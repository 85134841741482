import React, { useState } from "react";
import { Stack, Button } from "@mui/material";
import NavBarBottom from "../../components/NavBarBottom";
import ProgressBarTop from "../../components/ProgressBarTop";
import Box from "@mui/material/Box";
import PreviewIcon from "@mui/icons-material/Preview";
import { PROGRESS_BAR_TOTAL_STEPS } from "../../helper/colorExplorationParameters";
import Image from "mui-image";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";

export default function ColorExplorationCalibrationMicroscope() {
  const [microscopeImage, setMicroscopeImage] = useState("");
  const [microscopeImageData, setMicroscopeImageData] = useState("");
  const [microscopicImageId, setmicroscopicImageId] = useState("");
  const [clickedCoordinates, setClickedCoordinates] = useState([]); // Step 1
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [clickedCoordinatesImage, setClickedCoordinatesImage] = useState([]);
  const navigate = useNavigate();

  const handleImageClick = (event) => {
    const image = event.target;
    const imageRect = image.getBoundingClientRect();
    const x = event.clientX - imageRect.left;
    const y = event.clientY - imageRect.top;
    setClickedCoordinates((prevCoordinates) => [...prevCoordinates, { x, y }]);
    const x_a = Math.round(
      (event.clientX - imageRect.left) * (imageSize.width / imageRect.width)
    );
    const y_a = Math.round(
      (event.clientY - imageRect.top) * (imageSize.height / imageRect.height)
    );
    console.log("The clicked co-orfinates of the image");
    console.log(x_a+" "+y_a);
    // Store clicked coordinates in state
    const newCoordinatesArray = [...clickedCoordinatesImage, [y_a, x_a]];
    setClickedCoordinatesImage(newCoordinatesArray);
  };
  const handlePreviewMicroscopeImage = async (event) => {
    const formData = new FormData();
    formData.append('tiff_file', event.target.files[0]);
    axios.post('api/convert-to-png/', formData, {
      responseType: "blob",
    })
      .then(response => {
        // Set the PNG image data to state
        const blob = new Blob([response.data], { type: "image/png" });
        const blobUrl = URL.createObjectURL(blob);
        setMicroscopeImage(blobUrl);
        setMicroscopeImageData(blob);
        const img = new window.Image();
        img.src = blobUrl;
        img.onload = () => {
          setImageSize({ width: img.naturalWidth, height: img.naturalHeight });
        };
        console.log("image size " + imageSize.width + " " + imageSize.height);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  let handleClearCornerPoints = async (e) => {
    e.preventDefault();
    setClickedCoordinates([]);
    setClickedCoordinatesImage([]);
  }

  const nextPageClickFunc = async (navigatePageName) => {
    const formData = new FormData();
    formData.append("file", microscopeImageData);
    const finalCornerPointsSelection = JSON.stringify(clickedCoordinatesImage);
    formData.append("selectedCornerPoints", finalCornerPointsSelection)
    formData.append("microscopicCalibrationName", localStorage.getItem('microscopicCalibrationName'))
    await axios
      .post("api/calibration/microscope", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setmicroscopicImageId(res.data.microscopicCalibrationId)
          localStorage.setItem("microscopicCalibrationId", res.data.microscopicCalibrationId)
        }
      })
      .catch((err) => {
        console.log("Error in persisting calibration deatails" + err);
      });
    navigate(navigatePageName);

  };
  useEffect(() => {
    const handleDownload = async () => {
      console.log("Use effect downloading the image");
      try {
        const data = {
          fileDestination:
            localStorage.getItem("username") +
            "/" +
            localStorage.getItem("calibrationName") +
            "/microscope/microscopicCalibration.png",
        };
        const response = await axios.post("/api/calibration/file/get", data, {
          responseType: "blob",
        });
        const blob = new Blob([response.data], { type: "image/png" });
        const blobUrl = URL.createObjectURL(blob);
        // Create a blob URL from the file data
        setMicroscopeImage(blobUrl);
        const img = new window.Image();
        img.src = blobUrl;
        img.onload = () => {
          setImageSize({ width: img.naturalWidth, height: img.naturalHeight });
        };
      } catch (error) {
        console.log("Error:", error);
      }
    };
    handleDownload();
  }, []);
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={4}
      marginTop="2%"
    >
      <Box sx={{ width: "100%" }}>
        <ProgressBarTop
          currentStepNumber={4}
          totalStepNumber={PROGRESS_BAR_TOTAL_STEPS}
          progressBarName="Exploration"
        />
      </Box>

      <Box sx={{ width: "100%" }}>
        <ProgressBarTop
          currentStepNumber={2}
          totalStepNumber={3}
          progressBarName="Calibration"
        />
      </Box>

      <Button
        component="label"
        variant="contained"
        startIcon={<PreviewIcon />}
        style={{
          width: "60ch",
          height: "60px",
          margin: "2%",
        }}
      >
        {" "}
        choose microscope image
        <input
          style={{ display: "none" }}
          type="file"
          onChange={handlePreviewMicroscopeImage}
        />
      </Button>

      <div
        style={{
          width: "500px",
          height: "500px",
          position: "relative",
          padding: 0,
          margin: 0,
        }}
      >
        {
          microscopeImage &&
          <img
            src={microscopeImage}
            fit="contain"
            style={{ maxWidth: "100%", cursor: "crosshair" }}
            onClick={handleImageClick}
            onError={() => console.log("Error loading image")}
            onLoad={() => console.log("Image loaded successfully")}
          />

        }

        {clickedCoordinates.map((coordinate, index) => (
          <div
            key={index}
            style={{
              position: "absolute",
              top: coordinate.y,
              left: coordinate.x,
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor: "red",
            }}
          />
        ))}
        <Button
          component="label"
          variant="contained"
          style={{
            width: "60ch",
            height: "60px",
            margin: "2%",
          }}
          onClick={handleClearCornerPoints}
        >
          {" "}
          Clear Selected Corner Points
        </Button>
      </div>

      <NavBarBottom
        cancelPage={"/colorexplorationhome"}
        prevPage={"/colorexplorationmicroscopiccalibrationname"}
        nextPage={"/colorexplorationsetupcameracalibrationselect"}
        enableNextPage={
          (microscopeImage != null && microscopeImage.length > 0 && clickedCoordinatesImage.length >= 4) ? false : true
        }
        nextPageClickFunc={nextPageClickFunc}
      ></NavBarBottom>
    </Stack>
  );
}
