import { useNavigate } from "react-router-dom";
import { TextField, Stack, Button, MenuItem } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { DataGrid } from '@mui/x-data-grid';
import NavBarBottom from "../../components/NavBarBottom";





function ColorSelectionSelectExplorations() {
    const navigate = useNavigate();
    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 5
    })
    const [selectionModel, setSelectionModel] = useState([]);

    const columns = [
        { field: "id", headerName: "ID", width: 50 },
        { field: "name", headerName: "Exploration name", width: 200 },
        { field: "status", headerName: "Status", width: 150 },
        {
            field: "showExploration",
            headerName: "Show",
            width: 150,
            renderCell: (params) => {
                return (
                    <Button
                        onClick={(e) => onShowButtonClick(e, params.row)}
                        variant="filled"
                        startIcon={<FileOpenIcon />}
                    ></Button>
                );
            },
        },
    ];

    const onShowButtonClick = async (e, row) => {
        console.log("in onShowButtonClick!");
        //e.stopPropagation();
        //TODO: show the selected exploration
        console.log("The row content is ");
        console.log(row.id);
        console.log(row.status);
        localStorage.setItem("explorationId", row.id);
        if (row.status === "WIDTH_MEASUREMENT_DONE") {
            console.log("came inside here");
            navigate("/colorexplorationcolorcolor");
        }
        if (row.status === "COLOR_MEASUREMENT_DONE") {
            navigate("/colorexplorationcolorresolution");
        }

        if (row.status === "PENDING") {
            navigate("/colorexplorationcolorresolution");
        }
    };

    const handleSelectionModelChange = (newSelectionModel) => {
        setSelectionModel(newSelectionModel);
    };

    const nextPageClickFunc = (navigatePageName) => {
        localStorage.setItem("selectedExplorations", selectionModel);
        navigate(navigatePageName)
    }



    const useStyles = makeStyles({
        root: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
        },
    });
    const PAGE_SIZE = 5;

    useEffect(() => {
        const fetchData = async () => {
            await axios
                .get(`api/exploration/data?page=${pageState.page - 1}&per_page=${pageState.pageSize}&user_id=${localStorage.getItem("userId")}`)
                .then((res) => {
                    if (res.status === 200) {
                        const json = res.data;
                        setPageState(old => ({ ...old, isLoading: false, data: json.data, total: json.total_items }))
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        };
        fetchData()
    }, [pageState.page, pageState.pageSize])




    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={4}
            marginTop="2%"
        >
            <TextField
                disabled
                id="previousexplorations"
                label="Previous explorations"
                sx={{ width: "85ch" }}
                variant="filled"
            />

            <Stack style={{ height: 400, width: "85ch" }}>
                <DataGrid
                    autoHeight
                    rows={pageState.data}
                    rowCount={pageState.total}
                    loading={pageState.isLoading}
                    rowsPerPageOptions={[10, 30, 50, 70, 100]}
                    pagination
                    page={pageState.page - 1}
                    pageSize={pageState.pageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => {
                        setPageState(old => ({ ...old, page: newPage + 1 }))
                    }}
                    onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
                    columns={columns}
                    checkboxSelection
                    selectionModel={selectionModel}
                    onSelectionModelChange={handleSelectionModelChange}
                />
            </Stack>
            <NavBarBottom
                cancelPage={'/colorselectionhome'}
                prevPage={'/colorselectionobjective'}
                nextPage={'/colorselectionhome'}
                enableNextPage={(selectionModel != null) && (selectionModel.length > 0) ? false : true}
                nextPageClickFunc={nextPageClickFunc}>
            </NavBarBottom>
        </Stack>
    );
}

export default ColorSelectionSelectExplorations;
