import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Stack, Button, MenuItem } from "@mui/material";
import NavBarBottom from "../../components/NavBarBottom";
import ProgressBarTop from "../../components/ProgressBarTop"
import Box from '@mui/material/Box';
import PreviewIcon from '@mui/icons-material/Preview';
import DownloadIcon from '@mui/icons-material/Download';
import { PROGRESS_BAR_TOTAL_STEPS } from "../../helper/colorExplorationParameters";
import Image from 'mui-image';

export default function ColorExplorationSelectionVerification() {

    const [cameraColorImageVerification, setCameraColorImageVerification] = useState("");
    const navigate = useNavigate();

    const handleCameraColorImageChange = (event) => {
        setCameraColorImageVerification(URL.createObjectURL(event.target.files[0]));
    };

    // TODO: add function to download marking files
    const handleDownloadMarkingFiles = () => {
        console.log(' in download marking files.')
    }

    // TODO: if file preview available for color --> activate Next button. After Next button pressed, the images get uploaded to cloud.
    const nextPageClickFunc = (navigatePageName)=> {
        localStorage.setItem("cameraColorImageVerification", cameraColorImageVerification);
        navigate(navigatePageName)
    }
    useEffect(() =>{
        setCameraColorImageVerification(localStorage.getItem("cameraColorImageVerification"));
    },[]);

    return (
        <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={4}
            marginTop='2%'
        >

            <Box sx={{ width: '100%' }}>
                <ProgressBarTop currentStepNumber={6} totalStepNumber={PROGRESS_BAR_TOTAL_STEPS} progressBarName='Exploration' />
            </Box>

            <Box sx={{ width: '100%' }}>
                <ProgressBarTop currentStepNumber={2} totalStepNumber={2} progressBarName='Selection - Verification' />
            </Box>

            <Stack
                direction='row'
                spacing={4}

                >

                <Stack direction='column'
                    justifyContent='flex-start'
                    alignItems='center'>
                    <Button
                        component="label"
                        variant="contained"
                        startIcon={<DownloadIcon />}
                        style={{
                            width: "60ch",
                            height: "60px",
                            margin: "2%",
                        }}
                    > Download marking files and use in laser
                        <input style={{ display: 'none' }} type="file" onChange={handleDownloadMarkingFiles} />
                    </Button>
                </Stack>


                <Stack direction='column'
                    justifyContent='flex-start'
                    alignItems='center'>
                    <Button
                        component="label"
                        variant="contained"
                        startIcon={<PreviewIcon />}
                        style={{
                            width: "60ch",
                            height: "60px",
                            margin: "2%",
                        }}
                    > choose camera image of marked plate
                        <input style={{ display: 'none' }} type="file" onChange={handleCameraColorImageChange} />
                    </Button>
                    <Image
                        src={cameraColorImageVerification}
                        height='300px'
                        width='300px'
                        fit='contain'
                    />
                </Stack>
            </Stack>


            <NavBarBottom
                cancelPage={'/colorexplorationhome'}
                prevPage={'/colorexplorationselectionprimaries'}
                nextPage={'/colorexplorationselectionprimaries'}
                enableNextPage = {(cameraColorImageVerification!=null && cameraColorImageVerification.length > 0)? false: true} nextPageClickFunc={nextPageClickFunc}
                >
            </NavBarBottom>

        </Stack>
    );
}