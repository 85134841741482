import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Stack, Button, MenuItem } from "@mui/material";
import NavBarBottom from "../../components/NavBarBottom";
import ProgressBarTop from "../../components/ProgressBarTop"
import Box from '@mui/material/Box';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PreviewIcon from '@mui/icons-material/Preview';
import { PROGRESS_BAR_TOTAL_STEPS } from "../../helper/colorExplorationParameters";
import Image from 'mui-image';
import axios from "axios";

export default function ColorExplorationCalibrationCamera() {

    const [cameraWhiteImage, setCameraWhiteImage] = useState("");
    const [cameraColorImage, setCameraColorImage] = useState("");
    const [cameraCalibrationId, setcameraCalibrationId] = useState("");
    const navigate = useNavigate();

    const handleCameraWhiteImageChange = async(event) => {
        setCameraWhiteImage(URL.createObjectURL(event.target.files[0]));
        //setCameraWhiteImage(event.target.value);
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        var fileDestination = localStorage.getItem("username")+"/"+ localStorage.getItem("calibrationName")+"/camera/cameraWhiteImage.png"
        formData.append('fileDestination',fileDestination)
        try {
          await axios.post('api/upload/file-to-cloud', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          // File upload successful, do something
        } catch (error) {
          // Handle error
          console.log("Error while uploading the calibration file")
        }
    };

    const handleCameraColorImageChange = async(event) => {
        setCameraColorImage(URL.createObjectURL(event.target.files[0]));
        //alert(cameraColorImage);
        //setCameraColorImage(event.target.value);
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        var fileDestination = localStorage.getItem("username")+"/"+ localStorage.getItem("calibrationName")+"/camera/cameraColorImage.png"
        formData.append('fileDestination',fileDestination)
        try {
          await axios.post('api/upload/file-to-cloud', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          // File upload successful, do something
        } catch (error) {
          // Handle error
          console.log("Error while uploading the calibration file")
        }
    };

    //TODO: if file preview available for both white and color --> activate Next button. After Next button pressed, the images get uploaded to cloud.
    const nextPageClickFunc = (navigatePageName)=> {
        navigate(navigatePageName)
    }

    useEffect(() => {
        // setMicroscopeImage(localStorage.getItem("microscopeImage"));
        const handleDownloadWhiteImage = async () => {
          try {
            const data = {
              fileDestination : localStorage.getItem("username")+"/"+ localStorage.getItem("calibrationName")+"/camera/cameraWhiteImage.png"
            }
            const response = await axios.post('/api/calibration/file/get',data ,{
              responseType: 'blob',
            });
      
            // Create a blob URL from the file data
            const fileUrl = URL.createObjectURL(response.data);
      
            // Set the file URL to state
            setCameraWhiteImage(fileUrl);
          } catch (error) {
            console.log('Error:', error);
          }
        };
        handleDownloadWhiteImage()
        const handleDownloadColorImage = async () => {
            try {
              const data = {
                fileDestination : localStorage.getItem("username")+"/"+ localStorage.getItem("calibrationName")+"/camera/cameraColorImage.png"
              }
              const response = await axios.post('/api/calibration/file/get',data ,{
                responseType: 'blob',
              });
        
              // Create a blob URL from the file data
              const fileUrl = URL.createObjectURL(response.data);
        
              // Set the file URL to state
              setCameraColorImage(fileUrl);
            } catch (error) {
              console.log('Error:', error);
            }
          };
          handleDownloadColorImage()

      }, []);

    return (
        <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={4}
            marginTop='2%'
        >

            <Box sx={{ width: '100%' }}>
                <ProgressBarTop currentStepNumber={4} totalStepNumber={PROGRESS_BAR_TOTAL_STEPS} progressBarName='Exploration' />
            </Box>

            <Box sx={{ width: '100%' }}>
                <ProgressBarTop currentStepNumber={3} totalStepNumber={3} progressBarName='Calibration' />
            </Box>

            <Stack
                direction='row'
                spacing={4}

                >

                <Stack direction='column'
                    justifyContent='flex-start'
                    alignItems='center'>
                    <Button
                        component="label"
                        variant="contained"
                        startIcon={<PreviewIcon />}
                        style={{
                            width: "60ch",
                            height: "60px",
                            margin: "2%",
                        }}
                    > choose white image
                        <input style={{ display: 'none' }} type="file" onChange={handleCameraWhiteImageChange} />
                    </Button>
                    <Image
                        src={cameraWhiteImage}
                        height='300px'
                        width='300px'
                        fit='contain'
                    />
                </Stack>


                <Stack direction='column'
                    justifyContent='flex-start'
                    alignItems='center'>
                    <Button
                        component="label"
                        variant="contained"
                        startIcon={<PreviewIcon />}
                        style={{
                            width: "60ch",
                            height: "60px",
                            margin: "2%",
                        }}
                    > choose color image
                        <input style={{ display: 'none' }} type="file" onChange={handleCameraColorImageChange} />
                    </Button>
                    <Image
                        src={cameraColorImage}
                        height='300px'
                        width='300px'
                        fit='contain'
                    />
                </Stack>
            </Stack>


            <NavBarBottom
                cancelPage={'/colorexplorationhome'}
                prevPage={'/colorexplorationsetupmicroscopiccalibrationselect'}
                nextPage={'/colorexplorationsetupsummary'}
                enableNextPage = {(cameraWhiteImage!=null && cameraWhiteImage.length > 0 && cameraColorImage!=null && cameraColorImage.length >0)? false: true} nextPageClickFunc={nextPageClickFunc}
                >
            </NavBarBottom>

        </Stack>
    );
}