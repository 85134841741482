import React from "react";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Stack, Button } from '@mui/material';
import LoginIcon from "@mui/icons-material/Login";
import { LockReset, Visibility, VisibilityOff } from "@mui/icons-material";
import {  IconButton, OutlinedInput, InputLabel, InputAdornment, FormControl } from '@mui/material';


function Login() {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  
  const handleUserNameChange = (event) => {
    setUsername(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleClickPasswordForgotten = () => {
    navigate("/passwordforgotten");
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      username: username,
      password: password,
    };
    await axios
      .post("oraclase/auth/login/", data)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("username", username);
          localStorage.setItem("token", res.data.key);
          navigate("/");
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log('Error in login axios: ' + err);
      });
  };

  return (
    <Stack
      direction='column'
      justifyContent='center'
      alignItems='center'
      spacing={4}
      marginTop='2%'
    //alignSelf='center'
    >
      <TextField
        disabled
        id="oraclase"
        label='Your Login Page'
        sx={{ width: '85ch' }}
        variant="filled"
      />
      <TextField
        required
        id="username"
        label="Username"
        placeholder="john.doe@gmail.com"
        onChange={handleUserNameChange}
        value={username}
        type='text'
        sx={{ width: '85ch' }}
      />
       <FormControl sx={{ width: '85ch' }} variant="outlined">
        <InputLabel>Password</InputLabel>
        <OutlinedInput
          required={true} //TODO: required does not work
          label="password"
          id="outlined-adornment-password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={handlePasswordChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {/* <TextField
        required
        id="password"
        label="Password"
        placeholder="Insert password"
        onChange={handlePasswordChange}
        value={password}
        type='password'
        sx={{ width: '85ch' }}
      /> */}
      <Button variant="outlined" onClick={handleClickPasswordForgotten}>
        Don't remember password?
      </Button>

      <Button
        variant="contained"
        endIcon={<LoginIcon />}
        style={{ width:'88ch', height: "60px", marginLeft: "10px", marginTop: '2%' }}
        onClick={handleSubmit}
      >
        Log in
      </Button>
    </Stack>

  );
}

export default Login;