import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
  
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
function setAxiosAuthorization() {
  if ( localStorage.getItem("token")!=null && localStorage.getItem("token").length > 0) {
    axios.defaults.headers.common["Authorization"] =
      "token " + localStorage.getItem("token");
  }
}
console.log(process.env)
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
//axios.defaults.baseURL = "https://oraclase.app/";
setAxiosAuthorization();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
