import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import NextIcon from '@mui/icons-material/NavigateNext';
import BeforeIcon from '@mui/icons-material/NavigateBefore';
import CancelIcon from '@mui/icons-material/Cancel';
import { Colors } from "../styles/Colors";
import { useNavigate } from "react-router-dom";

const NavBarBottom = ({cancelPage, prevPage, nextPage, enableNextPage, nextPageClickFunc}) => {
  const navigate = useNavigate(); 
  const onClickCancelButton = (cancelPage)=> {
    localStorage.removeItem("explorationName")
    localStorage.removeItem("laser")
    localStorage.removeItem("materialName")
    localStorage.removeItem("materialId")
    localStorage.removeItem("widthValue")
    localStorage.removeItem("thicknessValue")
    localStorage.removeItem("cameraColorImage")
    localStorage.removeItem("cameraWhiteImage")
    localStorage.removeItem("microscopeImage")
    localStorage.removeItem("length")
    localStorage.removeItem("microscopicCalibrationName");
    localStorage.removeItem("cameraCalibrationName");
    localStorage.removeItem("microscopicCalibrationId");
    localStorage.removeItem("cameraCalibrationId");
    localStorage.removeItem("explorationId");
    localStorage.removeItem("microscopeColorImage");
    localStorage.removeItem("iterationNumber");
    localStorage.removeItem("cameraColorImage");
    localStorage.removeItem("cameraNormImage");
    navigate(cancelPage)
  }
  return (
    <Stack
      position= "absolute"
      bottom= "0"
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      alignSelf="flex-end"
      style={{ backgroundColor: Colors.navbarBottomBackground, height: "100px", width: "100%" }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        marginRight={"10px"}
      >
        <Button
          variant="contained"
          startIcon={<CancelIcon />}
          style={{ height: "40px", marginLeft: "10px" }}
          onClick={() => onClickCancelButton(cancelPage)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          startIcon={<BeforeIcon />}
          style={{ height: "40px", marginLeft: "10px" }}
          onClick={() => navigate( prevPage)}
        >
          Back
        </Button>
        <Button
          variant="contained"
          disabled = {enableNextPage}
          endIcon={<NextIcon />}
          style={{ height: "40px", marginLeft: "10px" }}
          onClick={() => nextPageClickFunc(nextPage)}
        >
          Next
        </Button>
      </Stack>
    </Stack>
  );
};

export default NavBarBottom;
