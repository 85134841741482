import React, { useEffect } from "react";
import { useState } from "react";
import { TextField, Stack, Button } from "@mui/material";
import NavBarBottom from "../../components/NavBarBottom";
import ProgressBarTop from "../../components/ProgressBarTop"
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useNavigate } from "react-router-dom";
import { PROGRESS_BAR_TOTAL_STEPS } from "../../helper/colorExplorationParameters";
import axios from "axios";


//TODO: read value from DB in componentDidMount
// const laserSystems = [
//     'Trumpf 5000',
//     'Trumpf 7000',
//     'Trumpf 10000',
// ];

export default function ColorExplorationSetupLaserSelect() {

    const navigate = useNavigate();
    const [laserSystemSelection, setLaserSystemSelection] = useState("");
    const [laserDevices, setLaserDevices] = useState([]);
    const handleLaserSystemSelection = (event) => {
        console.log(event.target.value)
        setLaserSystemSelection(event.target.value);
    };
    const nextPageClickFunc = (navigatePageName) => {
        localStorage.setItem("laser", laserSystemSelection);
        navigate(navigatePageName)
    }
    useEffect(() => {
        axios
        .get("api/color/explorer/laser/device")
        .then((res) => {
            if (Array.isArray(res.data) && res.data.length > 0) {
                const devices = res.data.map((item) => ({
                    id: item.id,
                    name: item.laser_device_name,
                  }));
                setLaserDevices(devices);
            }
        })
        .catch((err) => {
          console.log("Error in handleSubmit at axios: " + err);
        });
        setLaserSystemSelection(localStorage.getItem("laser"))
    }, []);

    return (
        <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={4}
            marginTop='2%'
        >

            <Box sx={{ width: '100%' }}>
                <ProgressBarTop currentStepNumber={2} totalStepNumber={PROGRESS_BAR_TOTAL_STEPS} progressBarName='Exploration' />
            </Box>

            <Stack
                direction='column'
                justifyContent='center'
                alignItems='center'
                spacing={4}
            >
                <TextField
                    disabled
                    label="Select from available laser systems"
                    sx={{ width: '85ch' }}
                    variant="filled"
                />
                <FormControl sx={{ m: 1, minWidth: 250 }}>
                    <InputLabel id="demo-simple-select-helper-label">Laser system name</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={laserSystemSelection}
                        label="Laser system name"
                        onChange={handleLaserSystemSelection}
                    >
                        {laserDevices.map((laserSystem) =>
                            (<MenuItem value={laserSystem.id}>{laserSystem.name}</MenuItem>)
                        )}
                    </Select>
                </FormControl>
            </Stack>


            <NavBarBottom
                cancelPage={'/colorexplorationhome'}
                prevPage={'/ColorExplorationObjectiveSelect'}
                nextPage={'/colorexplorationsetupsubstrateselect'}
                enableNextPage={(laserSystemSelection != null) ? false : true}
                nextPageClickFunc={nextPageClickFunc}>
            </NavBarBottom>

        </Stack>
    );
}