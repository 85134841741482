import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { TextField, Stack, Button, MenuItem } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

const SUBJECTS = [
  {
    value: "REPORT",
    label: "Report an issue",
  },
  {
    value: "MODULE_ACCESS",
    label: "Access software module",
  },
  {
    value: "REQUEST_TRAINING",
    label: "Request training",
  },
];

const NOTLOGGEDIN_SUBJECTS = [
  {
    value: "GET_CREDENTIALS",
    label: "Get  Credentials to access the System",
  },
];

const MODULE_NAMES = [
  {
    value: "1",
    label: "Color Explorer",
  },
  {
    value: "2",
    label: "Image reproduction",
  },
  {
    value: "3",
    label: "Roughness explorer",
  },
];

function Contact() {
  const navigate = useNavigate();
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [companyname, setCompanyName] = useState("");
  const [countryname, setCountryName] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [token, setToken] = useState("");
  const [showModulesDropdown, setShowModulesDropDown] = useState(false);
  const [ModuleAccess, setModuleAccess] = useState("");
  const [userId, setuserId] = useState("");
  const [moduleNames, setModuleNames] = useState("");

  useEffect(() => {
    const tk = localStorage.getItem("token");

    axios
    .get("api/modules")
    .then((res) => {
        if (Array.isArray(res.data) && res.data.length > 0) {
            const module = res.data.map((item) => ({
                id: item.id,
                name: item.module_name,
              }));
            setModuleNames(module);
        }
    })
    .catch((err) => {
      console.log("Error in handleSubmit at axios: " + err);
    });
    if (tk !== null && tk.length > 0) {
      setToken(tk);
      axios
        .get("oraclase/auth/user/")
        .then((res) => {
          if (res.status === 200) {
            setFirstName(res.data.first_name);
            setLastName(res.data.last_name);
            setEmail(res.data.email);
            setuserId(res.data.pk);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      axios
        .get("api/user/meta/")
        .then((res) => {
          if (res.status === 200) {
            setCompanyName(res.data[0].company_name);
            setPhone(res.data[0].phone_number)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };
  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };
  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
  };
  const handleCountryNameChange = (event) => {
    setCountryName(event.target.value);
  };
  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
    if (event.target.value === "MODULE_ACCESS") {
      setShowModulesDropDown(true);
    } else {
      setShowModulesDropDown(false);
    }
  };
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };
  const handleModuleAccessChange = (event) => {
    setModuleAccess(event.target.value);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    if (token.length === 0) {
      const data = {
        first_name: firstname,
        last_name: lastname,
        email: email,
        phone: phone, //TODO: check handling in backend
        company_name: companyname,
        country_name: countryname, //TODO: check handling in backend
        subject: subject, //TODO: check handling in backend
        message: message,
      };
      axios
        .post("api/userrequests/", data)
        .then((res) => {
          if (res.status === 201) {
            navigate("/");
          }
        })
        .catch((err) => {
          console.log("Error in handleSubmit at axios: " + err);
        });
    } else {
      const data = {
        auth_user: userId,
        request_type: subject, //TODO: check handling in backend
        message: message,
        module: ModuleAccess,
      };
      axios
        .post("api/oraclase/user/request/", data)
        .then((res) => {
          if (res.status === 201) {
            navigate("/");
          }
        })
        .catch((err) => {
          console.log("Error in handleSubmit at axios: " + err);
        });
    }
  };
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={4}
      marginTop="2%"
      //alignSelf='center'
    >
      <TextField
        disabled
        id="oraclase"
        label="Your Contact Form "
        sx={{ width: "85ch" }}
        variant="filled"
      />
      <Stack direction="row" spacing={4}>
        <TextField
          disabled={token.length !== 0 ? true : false}
          required
          id="firstname"
          label="First name"
          placeholder="John"
          onChange={handleFirstNameChange}
          value={firstname}
          type="text"
          sx={{ width: "40ch" }}
        />
        <TextField
          disabled={token.length !== 0 ? true : false}
          required
          id="lastname"
          label="Last name"
          placeholder="Doe"
          onChange={handleLastNameChange}
          value={lastname}
          type="text"
          sx={{ width: "40ch" }}
        />
      </Stack>
      <Stack direction="row" spacing={4}>
        <TextField
          disabled={token.length !== 0 ? true : false}
          required
          id="email"
          label="Email"
          placeholder="john.doe@gmail.com"
          onChange={handleEmailChange}
          value={email}
          type="email"
          sx={{ width: "40ch" }}
        />
        <TextField
          id="phone"
          label="Phone"
          placeholder="+444444444"
          onChange={handlePhoneChange}
          value={phone}
          type="tel"
          sx={{ width: "40ch" }}
        />
      </Stack>
      <Stack direction="row" spacing={4}>
        <TextField
          id="company"
          disabled={token.length !== 0 ? true : false}
          label="Company"
          placeholder="Oraclase gmbh"
          onChange={handleCompanyNameChange}
          value={companyname}
          type="text"
          sx={{ width: "40ch" }}
        />
        <TextField
          id="country"
          label="Country"
          placeholder="Germany"
          onChange={handleCountryNameChange}
          value={countryname}
          type="text"
          sx={{ width: "40ch" }}
        />
      </Stack>
      {token.length === 0 ? (
        <TextField
          id="subject"
          select
          required
          label="Subject"
          value={subject}
          onChange={handleSubjectChange}
          sx={{ width: "85ch" }}
        >
          {NOTLOGGEDIN_SUBJECTS.map((subject) => (
            <MenuItem key={subject.value} value={subject.value}>
              {subject.label}
            </MenuItem>
          ))}
        </TextField>
      ) : (
        <TextField
          id="subject"
          select
          required
          label="Subject"
          value={subject}
          onChange={handleSubjectChange}
          sx={{ width: "85ch" }}
        >
          {SUBJECTS.map((subject) => (
            <MenuItem key={subject.value} value={subject.value}>
              {subject.label}
            </MenuItem>
          ))}
        </TextField>
      )}
      {showModulesDropdown ? (
        <TextField
          id="modulesubject"
          select
          required
          label="ModuleSubject"
          value={ModuleAccess}
          onChange={handleModuleAccessChange}
          sx={{ width: "85ch" }}
        >
          {moduleNames.map((subject) => (
            <MenuItem key={subject.id} value={subject.id}>
              {subject.name}
            </MenuItem>
          ))}
        </TextField>
      ) : null}
      <TextField
        required
        id="message"
        label="Message"
        placeholder="Enter message"
        onChange={handleMessageChange}
        value={message}
        sx={{ width: "85ch" }}
        multiline
        maxRows={8}
      />

      <Button
        variant="contained"
        startIcon={<SendIcon />}
        style={{
          width: "88ch",
          height: "60px",
          marginLeft: "10px",
          marginTop: "2%",
        }}
        onClick={handleSubmit}
      >
        Send Message
      </Button>
    </Stack>
  );
}

export default Contact;
