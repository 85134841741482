import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Stack, Button, MenuItem } from "@mui/material";
import NavBarBottom from "../../components/NavBarBottom";
import ProgressBarTop from "../../components/ProgressBarTop";
import Box from "@mui/material/Box";
import { PROGRESS_BAR_TOTAL_STEPS } from "../../helper/colorExplorationParameters";
import axios from "axios";
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

export default function ColorSelectionSetUpName() {
  const [selectionName, setSelectionName] = useState("");
  const [enableSelectionName, setEnableSelectionName] = useState(false)
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSelectionName("")
    setOpen(false);
  };

  const navigate = useNavigate();
  const handleSelectionNameChange = (event) => {
    setSelectionName(event.target.value);
  };
  const nextPageClickFunc = async (navigatePageName) => {
    var proceed = false
    await axios
      .get(
        "api/color/selection/?name=" + selectionName
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length === 1) {
            proceed = false
          }
          if (res.data.length === 0) {
            proceed = true
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    if (!proceed) {
      handleClickOpen()
    }
    else {
      localStorage.setItem("selectionName", selectionName);
      navigate(navigatePageName);
    }
  };
  useEffect(() => {
    localStorage.removeItem("selectionId");
    if (selectionName === null || selectionName.length === 0) {
        setSelectionName(localStorage.getItem("selectionName"));
    }
  }, []);

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={4}
      marginTop="2%"
    >
      <Box sx={{ width: "100%" }}>
        <ProgressBarTop
          currentStepNumber={0}
          totalStepNumber={PROGRESS_BAR_TOTAL_STEPS}
          progressBarName="Selection"
        />
      </Box>

      
      <TextField
        disabled
        label="Insert a name for the new Selection"
        sx={{ width: "85ch" }}
        variant="filled"
      />

      <TextField
        required
        id="selectionName"
        disabled={enableSelectionName}
        label="Selection Name"
        placeholder="insert selection name"
        onChange={handleSelectionNameChange}
        value={selectionName}
        type="text"
        sx={{ width: "85ch" }}
      />

      <div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Error!</DialogTitle>
          <DialogContent sx={{ fontFamily: 'arial', marginTop: '2%', marginBottom: '2%' }}>
            The Selection name already exists. Please give another name
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClose}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <NavBarBottom
        cancelPage={"/colorselectionhome"}
        prevPage={"/colorselectionhome"}
        nextPage={"/colorselectionobjective"}
        enableNextPage={
          selectionName != null && selectionName.length > 0 ? false : true
        }
        nextPageClickFunc={nextPageClickFunc}
      ></NavBarBottom>
    </Stack>
  );
}
