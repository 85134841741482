import React from "react";
import { MODULE_LIST } from "../helper/moduleList"; // TODO: not used
import Card from "../components/CustomCard";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconColorExplorer from "../assets/ColorExploration.png";
import IconImageRepro from "../assets/ImageReproduction.png";
import IconSelectionExplorer from "../assets/ColorSelection.png";
import { TextField, Stack, Button, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";

const useStyles = makeStyles({
  gridContainer: {
    paddingLeft: "10px",
    paddingRight: "10px",
  },
});

function Home() {
  const classes = useStyles();
  const [colorExplorerAccess, setColorExplorerAccess] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userId, setuserId] = useState("");
  useEffect(() => {
    axios
      .get("oraclase/auth/user/")
      .then((res) => {
        if (res.status === 200) {
          setFirstName(res.data.first_name);
          setLastName(res.data.last_name);
          setEmail(res.data.email);
          setuserId(res.data.pk)
          localStorage.setItem("userId", res.data.pk)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Stack
      direction='column'
      justifyContent='center'
      alignItems='center'
      spacing={4}
      marginTop='2%'
    >
      <Typography variant="h4"> Software modules</Typography>
      {/* {MODULE_LIST.map((mod,key) => {
               return <ModuleItem  key={key} name={mod.name} description={mod.description}/>
            })} */}
      <Grid
        container
        spacing={1}
        className={classes.gridContainer}
      >
        <Grid item xs={12} sm={6} md={4}>
          <Card
            image={IconColorExplorer}
            title="Color Explorer"
            description="This module finds the best laser process parameters to get desired colors with a suitable resolution. The identified colors and resolutions can be used as inputs for image reproduction module to mark any images."
            navigateTo={"ac8cf3df-a98a-45ed-a53d-d1eb884a07ad"}
            navigatePageName={"colorexplorationhome"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            image={IconImageRepro}
            title="Image Reproduction"
            description="In this module you upload an image and the explored color and resolution data are used to generate laser marking file. The marking file marks the image as similar as possible to the uploaded image."
            navigateTo={"ae6ac1d0-49be-46eb-8d27-a789f6cc92ab"}
            navigatePageName={"colorexplorationhome"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            image={IconSelectionExplorer}
            title="Selection Explorer"
            description="This module finds best colors from the selected exploration and show the results of the selected colors."
            navigateTo={"e569d1e1-feb6-4c8f-89d2-a41005a58b96"}
            navigatePageName={"colorselectionhome"}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}

export default Home;
