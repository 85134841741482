import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { TextField, Stack } from "@mui/material";
import NavBarBottom from "../../components/NavBarBottom";
import ProgressBarTop from "../../components/ProgressBarTop"
import Box from '@mui/material/Box';
import { PROGRESS_BAR_TOTAL_STEPS } from "../../helper/colorExplorationParameters";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';



export default function ColorExplorationSetupSubstrateCreate() {

    const [material, setMaterialName] = useState("");
    const [length, setLengthValue] = useState("");
    const [width, setWidthValue] = useState("");
    const [thickness, setThicknessValue] = useState("");
    const navigate = useNavigate();

    const handleMaterialNameChange = (event) => {
        setMaterialName(event.target.value);
    };

    const handleLengthChange = (event) => {
        setLengthValue(event.target.value);
    };

    const handleWidthChange = (event) => {
        setWidthValue(event.target.value);
    };

    const handleThicknessChange = (event) => {
        setThicknessValue(event.target.value);
    };

  
    const nextPageClickFunc = (navigatePageName) => {
        localStorage.setItem("materialName", material);
        localStorage.setItem("length", length);
        localStorage.setItem("widthValue", width);
        localStorage.setItem("thicknessValue", thickness);
        navigate(navigatePageName)
    }
    useEffect(() => {
        localStorage.removeItem("materialId")
        setMaterialName(localStorage.getItem("materialName"))
        setLengthValue(localStorage.getItem("length"))
        setWidthValue(localStorage.getItem("widthValue"))
        setThicknessValue(localStorage.getItem("thicknessValue"))
    }, []);

    return (
        <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={4}
            marginTop='2%'
        >
            <Box sx={{ width: '100%' }}>
                <ProgressBarTop currentStepNumber={3} totalStepNumber={PROGRESS_BAR_TOTAL_STEPS} progressBarName='Exploration' />
            </Box>
            <TextField
                disabled
                label="Define substrate"
                sx={{ width: '85ch' }}
                variant="filled"
            />

            <TextField
                required
                id="material"
                label="Material"
                placeholder="Steel"
                onChange={handleMaterialNameChange}
                value={material}
                type="text"
                sx={{ width: "40ch" }}
            />

            <TextField
                disabled
                label="Plate dimensions"
                sx={{ width: '85ch' }}
                variant="filled"
            />

            <TextField
                required
                id="length"
                label="Length (mm)"
                placeholder="20"
                onChange={handleLengthChange}
                value={length}
                type="number"
                sx={{ width: "40ch" }}
            />

            <TextField
                required
                id="width"
                label="Width (mm)"
                placeholder="20"
                onChange={handleWidthChange}
                value={width}
                type="number"
                sx={{ width: "40ch" }}
            />

            <TextField
                required
                id="thickness"
                label="Thickness (mm)"
                placeholder="2"
                onChange={handleThicknessChange}
                value={thickness}
                type="number"
                sx={{ width: "40ch" }}
            />

            <NavBarBottom cancelPage={'/colorexplorationhome'} prevPage={'/colorexplorationsetupsubstrateselect'} nextPage={'/colorexplorationsetupmicroscopiccalibrationselect'} enableNextPage = {(material!=null && length!=null && width!=null && thickness!=null
             && material.length >0 && length.length > 0 && width.length > 0 && thickness.length > 0)?false:true} nextPageClickFunc={nextPageClickFunc}></NavBarBottom>

        </Stack>
    );
}