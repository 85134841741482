import React, { useState, useRef, useEffect } from "react";
import oraclaseLogo from "../assets/oraclase_logo_black_bg.png";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import HomeIcon from "@mui/icons-material/Home";
import AboutIcon from "@mui/icons-material/Info";
import ContactIcon from "@mui/icons-material/Email";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Colors } from "../styles/Colors";
import { useNavigate } from "react-router-dom";

const NavBarTop = () => {
  const [pageName, setPageName] = useState("Home");
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  function changePageName(name) {
    setPageName(name);
  }
  function logoutClicked(){
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("userId")
    navigate("/");
    window.location.reload();
  }
  useEffect(() => {
    const tk = localStorage.getItem("token");
    if (tk != null && tk.length > 0) {
      setToken(tk);
    }
  });
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      style={{ backgroundColor: Colors.navbarBackground, height: "70px" }}
    >
      <img src={oraclaseLogo} alt="" style={{ height: "70px",width: "120px",padding : "25px" }} />
      <text
        style={{
          fontFamily: "Arial",
          fontSize: 30,
          color: "white",
          width: "20%",
        }}
      >
        {pageName}
      </text>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        marginRight={"10px"}
      >
        <Button
          variant="contained"
          startIcon={<HomeIcon />}
          style={{ height: "40px", marginLeft: "10px" }}
          onClick={() => changePageName("Home")}
          component={Link}
          to="/"
        >
          Home
        </Button>
        <Button
          variant="contained"
          startIcon={<AboutIcon />}
          style={{ height: "40px", marginLeft: "10px" }}
          onClick={() => changePageName("About")}
          component={Link}
          to="/about"
        >
          About
        </Button>
        <Button
          variant="contained"
          startIcon={<ContactIcon />}
          style={{ height: "40px", marginLeft: "10px" }}
          onClick={() => changePageName("Contact")}
          component={Link}
          to="/contact"
        >
          Contact
        </Button>
        {token.length === 0 ? (
          <Button
            variant="contained"
            startIcon={<LoginIcon />}
            style={{ height: "40px", marginLeft: "10px" }}
            onClick={() => changePageName("Login")}
            component={Link}
            to="/login"
          >
            Login
          </Button>
        ) : null}
        {token.length !== 0 ? (
          <Button
            variant="contained"
            startIcon={<AccountCircleIcon />}
            style={{ height: "40px", marginLeft: "10px" }}
            onClick={() => changePageName("Account")}
            component={Link}
            to="/profile"
          >
            Profile
          </Button>
        ) : null}
        {token.length !== 0 ? (
          <Button
            variant="contained"
            startIcon={<LogoutIcon />}
            style={{ height: "40px", marginLeft: "10px" }}
            onClick={() => logoutClicked()}
            component={Link}
          >
            Logout
          </Button>
        ) : null}
      </Stack>
    </Stack>
  );
};

export default NavBarTop;
