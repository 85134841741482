import React, { useEffect } from "react";
import { useState } from "react";
import { TextField, Stack, Button } from "@mui/material";
import NavBarBottom from "../../components/NavBarBottom";
import ProgressBarTop from "../../components/ProgressBarTop";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import { PROGRESS_BAR_TOTAL_STEPS } from "../../helper/colorExplorationParameters";
import axios from "axios";



export default function ColorExplorationSetupCameraCalibrationSelect() {
  const navigate = useNavigate();
  const [calibrationSelection, setCalibrationSelection] = useState("");
  const[calibrationNames,setCalibrationNames] = useState([]);

  const handleCalibrationSelection = (event) => {
    setCalibrationSelection(event.target.value);
    localStorage.setItem("cameraCalibrationId", event.target.value);
  };

  const nextPageClickFunc = (navigatePageName)=> {
    navigate(navigatePageName)
}
  useEffect(() => {
    axios
      .get(
        "api/color/explorer/color/calibration/?auth_user_id=" + localStorage.getItem("userId")
      )
      .then((res) => {
        if (res.status === 200) {
            const tempCameraCalibrationNames = []
            for(let i=0;i<res.data.length;i++){
                tempCameraCalibrationNames[i] = {
                    "id" : res.data[i].id,
                    "name" : res.data[i].name
                }
            }
            setCalibrationNames(tempCameraCalibrationNames)
        }
      })
      .catch((err) => {
        console.log(err);
      });
      setCalibrationSelection(localStorage.getItem("cameraCalibrationId"))  
  }
  , []);
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={4}
      marginTop="2%"
    >
      <Box sx={{ width: "100%" }}>
        <ProgressBarTop
          currentStepNumber={4}
          totalStepNumber={PROGRESS_BAR_TOTAL_STEPS}
          progressBarName="Exploration"
        />
      </Box>

      <Stack direction="row" spacing={4}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <TextField
            disabled
            label="create a new Camera calibration"
            sx={{ width: "85ch" }}
            variant="filled"
          />
          <Button
            variant="contained"
            style={{
              width: "43ch",
              height: "60px",
              marginLeft: "10px",
              marginTop: "2%",
            }}
            onClick={() => navigate("/ColorExplorationCameraCalibrationName")}
          >
            New Camera calibration
          </Button>
        </Stack>

        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <TextField
            disabled
            label="Select from previous camera calibrations"
            sx={{ width: "85ch" }}
            variant="filled"
          />
          <FormControl sx={{ m: 1, minWidth: 265 }}>
            <InputLabel id="demo-simple-select-helper-label">
              Camera Calibration name
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={calibrationSelection}
              label="Microscopic Calibration Name"
              onChange={handleCalibrationSelection}
            >
              {calibrationNames.map((calibrationName) => (
                <MenuItem value={calibrationName.id}>{calibrationName.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Stack>

      <NavBarBottom
        cancelPage={"/colorexplorationhome"}
        prevPage={"/colorexplorationsetupmicroscopiccalibrationselect"}
        nextPage={"/colorexplorationsetupsummary"}
        enableNextPage={
          localStorage.getItem("cameraCalibrationId") != null &&
          localStorage.getItem("cameraCalibrationId").length > 0
            ? false
            : true
        }
        nextPageClickFunc={nextPageClickFunc}
      ></NavBarBottom>
    </Stack>
  );
}
