import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const ProgressBarTop = ({ currentStepNumber, totalStepNumber, progressBarName }) => {
  let progress = 100 * currentStepNumber / totalStepNumber;

  return (
    <Box >
      <Typography align="center" variant="body2" color="text.secondary">{progressBarName}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            progress,
          )}%`}</Typography>
        </Box>
      </Box>
    </Box>

  );
}
export default ProgressBarTop;

