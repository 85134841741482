import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Stack, Button, MenuItem } from "@mui/material";
import NavBarBottom from "../../components/NavBarBottom";
import ProgressBarTop from "../../components/ProgressBarTop"
import Box from '@mui/material/Box';
import PreviewIcon from '@mui/icons-material/Preview';
import DownloadIcon from '@mui/icons-material/Download';
import { PROGRESS_BAR_TOTAL_STEPS } from "../../helper/colorExplorationParameters";
import Image from 'mui-image';
import axios from "axios";


export default function ColorExplorationColorColor() {

    const [cameraColorImage, setCameraColorImage] = useState("");
    const [cameraColorImageData, setCameraColorImageData] = useState("");
    const [imageSizeColor, setImageSizeColor] = useState({ width: 0, height: 0 });

    const [cameraNormImage, setCameraNormImage] = useState("");
    const [cameraNormImageData, setCameraNormImageData] = useState("");
    const [imageSizeNorm, setImageSizeNorm] = useState({ width: 0, height: 0 });
    const [cornerPoints, setCornerPoints] = useState([]);
    const imageRef = useRef(null);
    const [clickedCoordinatesImage, setClickedCoordinatesImage] = useState([]);
    const [clickedCoordinates, setClickedCoordinates] = useState([]); // Step 1
    const [imageRect, setImageRect] = useState(null);


    const navigate = useNavigate();

    const getIterationNumber = async () => {
        const request_data = {
            "explorationId": localStorage.getItem("explorationId")
        }
        const response = await axios.post('api/exploration/iteration/number', request_data);
        localStorage.setItem("iterationNumber", response.data.iterationNumber);
        console.log("results came");
        console.log(response.data.iterationNumber);
        // axios.post('api/exploration/iteration/number', request_data)
        //     .then(response => {
        //         localStorage.setItem("iterationNumber", response.data.iterationNumber);
        //         console.log("results came");
        //         console.log(response.data.iterationNumber);
        //     })
        //     .catch(error => {
        //         console.error('Error:', error);
        //     });
    }

    const handleImageClick = (event) => {
        const image = event.target;
        const imageRect = image.getBoundingClientRect();
        console.log("The event client X");
        console.log(event.clientX);
        console.log("The event client Y");
        console.log(event.clientY);
        const x = event.clientX - imageRect.left;
        const y = event.clientY - imageRect.top;
        setClickedCoordinates((prevCoordinates) => [...prevCoordinates, { x, y }]);
        console.log("The clicked co-ordinates that is shown in UI");
        console.log(x + " " + y);
        const x_a = Math.round(
            (event.clientX - imageRect.left) * (imageSizeColor.width / imageRect.width)
        );
        const y_a = Math.round(
            (event.clientY - imageRect.top) * (imageSizeColor.height / imageRect.height)
        );
        console.log("The clicked co-orfinates of the image");
        console.log(x_a + " " + y_a);
        // Store clicked coordinates in state
        const newCoordinatesArray = [...clickedCoordinatesImage, [y_a, x_a]];
        setClickedCoordinatesImage(newCoordinatesArray);
        const newCoordinatesArrayCornerPoints = [...cornerPoints, [x_a, y_a]];
        setCornerPoints(newCoordinatesArrayCornerPoints);
    };

    let handleClearCornerPoints = async (e) => {
        e.preventDefault();
        setClickedCoordinates([]);
        setClickedCoordinatesImage([]);
        setCornerPoints([]);
    }

    const handleCameraColorImageChange = async (event) => {
        // setCameraColorImage(URL.createObjectURL(event.target.files[0]));
        setCameraColorImageData(event.target.files[0])
        const formData = new FormData();
        formData.append('tiff_file', event.target.files[0]);
        const img = new window.Image();
        formData.append('explorationId', localStorage.getItem("explorationId"));
        formData.append('iterationNumber', localStorage.getItem("iterationNumber"));
        formData.append('userId', localStorage.getItem("userId"));
        await axios.post('api/convert-to-png/nef/', formData, {
            responseType: "blob",
        })
            .then(response => {
                // Set the PNG image data to state
                const blob = new Blob([response.data], { type: "image/png" });
                const blobUrl = URL.createObjectURL(blob);
                setCameraColorImage(blobUrl);
                //setCameraColorImageData(blob);
                img.src = blobUrl;
                img.onload = () => {
                    setImageSizeColor({ width: img.naturalWidth, height: img.naturalHeight });
                };
                console.log("image size " + imageSizeColor.width + " " + imageSizeColor.height);
            })
            .catch(error => {
                console.error('Error:', error);
            });
        const formData1 = new FormData();
        formData1.append('file', event.target.files[0]);
        formData1.append('explorationId', localStorage.getItem("explorationId"));
        formData1.append('iterationNumber', localStorage.getItem("iterationNumber"));
        formData1.append('userId', localStorage.getItem("userId"));
        formData1.append('filename', "MainImg.NEF");
        try {
            const response = await axios.post('api/corner/points', formData1, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            // File upload successful, do something
            console.log("The corner points are");
            console.log(response.data.cornerPoints);
            setClickedCoordinates(response.data.cornerPoints);
            if (imageRef.current) {
                console.log("came inside this if as it is not null");
                console.log(imageRef.current)
                const imageRect = imageRef.current.getBoundingClientRect();
                console.log("image rect ");
                console.log(imageRect);
                console.log(imageSizeColor.width);
                console.log(imageSizeColor.height);
                const updatedCoordinatesArray = response.data.cornerPoints.map(
                    (coordinate) => {
                        const x = (coordinate[0] / img.naturalWidth) * imageRef.current.clientWidth;
                        const y = (coordinate[1] / img.naturalHeight) * imageRef.current.clientHeight;
                        return { x, y };
                    }
                );
                console.log("updated the corner points that got from the backend");
                console.log(updatedCoordinatesArray);
                setClickedCoordinates(updatedCoordinatesArray);
            }
            else {
                console.log("The image ref is still null");
            }
            setCornerPoints(response.data.cornerPoints);
        } catch (error) {
            // Handle error
            console.log("Error while uploading the calibration file")
        }
    };
    //handleCameraNormImageChange
    const handleCameraNormImageChange = (event) => {
        setCameraNormImage(URL.createObjectURL(event.target.files[0]));
        setCameraNormImageData(event.target.files[0])
        const formData = new FormData();
        formData.append('tiff_file', event.target.files[0]);
        axios.post('api/convert-to-png/', formData, {
            responseType: "blob",
        })
            .then(response => {
                // Set the PNG image data to state
                const blob = new Blob([response.data], { type: "image/png" });
                const blobUrl = URL.createObjectURL(blob);
                setCameraNormImage(blobUrl);
                //setCameraNormImageData(blob);
                const img = new window.Image();
                img.src = blobUrl;
                img.onload = () => {
                    setImageSizeNorm({ width: img.naturalWidth, height: img.naturalHeight });
                };
                console.log("image size " + imageSizeNorm.width + " " + imageSizeNorm.height);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    // TODO: add function to download marking files
    const handleDownloadMarkingFiles = () => {
        console.log(' in download marking files.')
    }

    // TODO: if file preview available for color --> activate Next button. After Next button pressed, the images get uploaded to cloud.
    const nextPageClickFunc = async (navigatePageName) => {
        localStorage.setItem("cameraColorImage", cameraColorImage);
        localStorage.setItem("cameraNormImage", cameraNormImage);
        const formData = new FormData();
        console.log(cameraColorImageData);
        console.log('Size of cameraColorImageData:', cameraColorImageData.size);
        formData.append('file', cameraColorImageData);
        var fileDestination = "users/" + localStorage.getItem("userId") + "/explorations/" + localStorage.getItem("explorationId") + "/Q" + localStorage.getItem("iterationNumber") + "/MainImg.NEF"
        formData.append('fileDestination', fileDestination)
        try {
            await axios.post('api/upload/file-to-cloud', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            // File upload successful, do something
        } catch (error) {
            // Handle error
            console.log("Error while uploading the camera color file")
        }
        const formData1 = new FormData();
        formData1.append('file', cameraNormImageData);
        var fileDestination = "users/" + localStorage.getItem("userId") + "/explorations/" + localStorage.getItem("explorationId") + "/Q" + localStorage.getItem("iterationNumber") + "/NormImg.NEF"
        formData1.append('fileDestination', fileDestination)
        try {
            await axios.post('api/upload/file-to-cloud', formData1, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            // File upload successful, do something
        } catch (error) {
            // Handle error
            console.log("Error while uploading the camera norm file")
        }

        const request_data = {
            "explorationId": localStorage.getItem("explorationId"),
            "cornerPoints": cornerPoints
        }
        await axios.post('api/measure/color', request_data)
            .then(response => {
                navigate(navigatePageName);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }
    const handleDownloadClick = async () => {
        const data = {
            "explorationId": localStorage.getItem("explorationId"),
            "patchType": "FULL_CLUSTER"
        }
        const response = await axios.post("api/download/marking/file", data, {
            responseType: "blob",
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        var filename;
        if(localStorage.getItem("iterationNumber") == 0){
            filename = 'marking-files-color_p0.zip'
        }
        else{
            const fname = 'marking-files-color_q'
            const fileExtension = '.zip'
            filename = `${fname}_${localStorage.getItem("iterationNumber")-1}${fileExtension}`;
        }
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    };

    useEffect(() => {
        // setCameraColorImage(localStorage.getItem("cameraColorImage"));
        // setCameraNormImage(localStorage.getItem("cameraNormImage"));
        getIterationNumber();
    }, []);

    return (
        <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={4}
            marginTop='2%'
        >

            <Box sx={{ width: '100%' }}>
                <ProgressBarTop currentStepNumber={5} totalStepNumber={PROGRESS_BAR_TOTAL_STEPS} progressBarName='Exploration' />
            </Box>

            <Box sx={{ width: '100%' }}>
                <ProgressBarTop currentStepNumber={2} totalStepNumber={3} progressBarName='Color - Color' />
            </Box>

            <Stack
                direction='row'
                spacing={4}

            >

                <Stack direction='column'
                    justifyContent='flex-start'
                    alignItems='center'>
                    <Button
                        component="label"
                        variant="contained"
                        onClick={handleDownloadClick}
                        startIcon={<DownloadIcon />}
                        style={{
                            width: "60ch",
                            height: "60px",
                            margin: "2%",
                        }}
                    > Download marking files and use in laser
                    </Button>
                </Stack>


                <Stack direction='column'
                    justifyContent='flex-start'
                    alignItems='center'>
                    <Button
                        component="label"
                        variant="contained"
                        startIcon={<PreviewIcon />}
                        style={{
                            width: "60ch",
                            height: "60px",
                            margin: "2%",
                        }}
                    > choose camera image of marked plate
                        <input style={{ display: 'none' }} accept=".nef" type="file" onChange={handleCameraColorImageChange} />
                    </Button>
                    <div
                        style={{
                            width: "500px",
                            height: "500px",
                            position: "relative",
                            padding: 0,
                            margin: 0,
                        }}
                    >
                        {
                            cameraColorImage &&
                            <img
                                src={cameraColorImage}
                                fit="contain"
                                ref={imageRef}
                                style={{ maxWidth: "100%", cursor: "crosshair" }}
                                onClick={handleImageClick}
                                onError={() => console.log("Error loading image")}
                                onLoad={() => console.log("Image loaded successfully")}
                            />

                        }

                        {clickedCoordinates.map((coordinate, index) => (
                            <div
                                key={index}
                                style={{
                                    position: "absolute",
                                    top: coordinate.y,
                                    left: coordinate.x,
                                    width: "5px",
                                    height: "5px",
                                    borderRadius: "30%",
                                    backgroundColor: "red",
                                }}
                            />
                        ))}
                        <Button
                            component="label"
                            variant="contained"
                            style={{
                                width: "60ch",
                                height: "60px",
                                margin: "2%",
                            }}
                            onClick={handleClearCornerPoints}
                        >
                            {" "}
                            Clear Selected Corner Points
                        </Button>
                    </div>
                </Stack>

                <Stack direction='column'
                    justifyContent='flex-start'
                    alignItems='center'>
                    <Button
                        component="label"
                        variant="contained"
                        startIcon={<PreviewIcon />}
                        style={{
                            width: "60ch",
                            height: "60px",
                            margin: "2%",
                        }}
                    > choose Normalization image of marked plate
                        <input style={{ display: 'none' }} accept=".nef" type="file" onChange={handleCameraNormImageChange} />
                    </Button>
                    <Image
                        src={cameraNormImage}
                        height='300px'
                        width='300px'
                        fit='contain'
                    />
                </Stack>
            </Stack>


            <NavBarBottom
                cancelPage={'/colorexplorationhome'}
                prevPage={'/colorexplorationmeasurewidth'}
                nextPage={'/colorexplorationcolorsummary'}
                enableNextPage={(cameraColorImage != null && cameraColorImage.length > 0) ? false : true} nextPageClickFunc={nextPageClickFunc}
            >
            </NavBarBottom>

        </Stack>
    );
}