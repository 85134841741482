import { Grid, Paper, TextField, Drawer, Modal, Stack, Button } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import ImagePreview_backup from './ImagePreview_backup';
import ImagePreview from './ImagePreview';
import axios from "axios";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import LazyLoad from 'react-lazyload';




const ImageGrid = ({ imageObjects, gridSize,updateGridCellContent }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [data, setData] = useState([]);
  const gridItems = imageObjects.slice(0, gridSize * gridSize);
  const [modalOpen, setModalOpen] = useState(false);
  const [coordinates, setCoordinates] = useState(null);
  const [cluster_width, setCluster_width] = useState(null);
  const [row, setRow] = useState(null);
  const [column, setColumn] = useState(null);
  const [width_pixel, setWidth_pixel] = useState(null);
  const [width_metric, setWidth_metric] = useState(null);
  const [patch_type, setPatch_type] = useState(null);
  const [method, setMethod] = useState(null);
  const [index, setIndex] = useState(null);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [selectedValue, setSelectedValue] = useState('');
  const canvasRef = useRef(null);
  const [showEnterValue, setShowEnterValue] = useState(false);
  const imageRef = useRef(null);
  const [canvasReady, setCanvasReady] = useState(false);
  const [widthValue, setWidthValue] = useState(-1);
  const [calibrationValue, setCalibrationValue] = useState(0);
  const [newCoordinates, setNewCoordinates] = useState([-1,-1]);
  const [newWidth_pixel, setNewWidth_pixel] = useState(-1);
  const [points, setPoints] = useState([]);
  const [imgPoints, setImgPoints] = useState([]);


  const handleImageClickGrid = (image, index, coordinates, cluster_width, row, column, width_pixel, width_metric, method, patch_type) => {
    console.log("came inside the image click function in image grid");
    setSelectedImage(image);
    const imageElement = document.getElementById(`image-${index}`);
    localStorage.setItem("previewImage", image);
    setModalOpen(true);
    setSelectedImageIndex(index);
    setCoordinates(coordinates);
    setCluster_width(cluster_width);
    setRow(row);
    setColumn(column);
    setWidth_pixel(width_pixel);
    setWidth_metric(width_metric);
    setMethod(method);
    setPatch_type(patch_type);
    setIndex(index);
    if (imageElement) {
      // Set the src attribute to a placeholder or empty string initially
      imageElement.src = image;
      // Create a new Image object
      const img = new Image();
      img.src = image;
      // Set up an onload event handler for the new image
      img.onload = () => {
        imageElement.src = img.src;
      };

      img.onerror = () => {
        console.error('Failed to load the image');
      };
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedImage(null);
    setSelectedImageIndex(null);
  };

  useEffect(() => {
    setData(imageObjects);
    setSelectedImage(null);
    setSelectedImageIndex(null);
  }, [data]);

  const handleImageClick = (event) => {
    console.log("The canvas ready state" + canvasReady);
    if (!canvasReady) return;
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = (event.clientX - rect.left) / rect.width; // Calculate x-coordinate as a percentage
    const y = (event.clientY - rect.top) / rect.height; // Calculate y-coordinate as a percentage

    const img = new Image();
    img.src = localStorage.getItem("previewImage");

    img.onload = () => {
      const imgWidth = img.width;
      const imgHeight = img.height;
      console.log("The image loaded dimensions are");
      console.log(imgWidth + " " + imgHeight);
      const xPixel = x * imgWidth;
      const yPixel = y * imgHeight;
      console.log("The image clicked pixels are");
      console.log(xPixel + " " + yPixel);

      // Use the functional form of setState to ensure access to the latest state
      setImgPoints(prevImgPoints => {
        // Limit selection to a maximum of 2 points
        if (prevImgPoints.length < 2) {
          return [...prevImgPoints, { xPixel, yPixel }];
        }
        return prevImgPoints;
      });
    };
    console.log("The canvas clicked image points are ");
    console.log(x + " " + y);
    // Limit selection to a maximum of 2 points
    setPoints(prevPoints => {
      if (prevPoints.length < 2) {
        return [...prevPoints, { x, y }];
      }
      return prevPoints;
    });
  };

  let handleClearCornerPoints = async (e) => {
    e.preventDefault();
    setPoints([]);
    setImgPoints([]);
    setWidthValue(0);
  }

  const handleRadioClick = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value === 'Marked') {
      setShowEnterValue(true);
    } else {
      setShowEnterValue(false);
    }
  };
  const calculateWidth = () => {
    console.log("Came inside the calculate width function");
    console.log(imgPoints.length);
    if (imgPoints.length === 2) {
      console.log("Camer inside this as it has 2 points");
      const [point1, point2] = imgPoints;
      const y1 = point1.yPixel;
      const y2 = point2.yPixel;
      console.log("The y values are as follows");
      console.log(y1 + " " + y2);
      const width = Math.abs(y2 - y1);
      setNewWidth_pixel(width);
      console.log("before calculating the width the calibration value" + calibrationValue);
      const calibrated_width = Math.round(width) * calibrationValue;
      console.log(calibrationValue)
      console.log("The calibrated width");
      console.log(calibrated_width)
      setWidthValue(calibrated_width);
      setNewCoordinates([y1, y2]);

    } else {
      setWidthValue(0);
    }
    console.log("The width value is " + widthValue);
  };

  const saveChangesClick = async () => {
    console.log("saving the user changes for the preview");
    var co_ordinate1 = newCoordinates[0];
    var co_ordinate2 = newCoordinates[1];
    if( selectedValue == "Burnt" || selectedValue == "UnMarked"){
      console.log("camer inside this as the selected value is burnt or unmarked");
      co_ordinate1 = -1;
      co_ordinate2 = -1;
      // widthValue = -1;
      // newWidth_pixel = -1;
    }
    const request_data = {
      "explorationId": localStorage.getItem("explorationId"),
      "edge_a_location": co_ordinate1 ,
      "edge_b_location": co_ordinate2,
      "cluster_width": widthValue,
      "row": row,
      "column": column,
      "width_pixel": newWidth_pixel,
      "width_metric": widthValue,
      "method": method,
      "patch_classification": selectedValue
    }
    await axios.post('api/update/width/measurement', request_data)
      .then(response => {

      })
      .catch(error => {
        console.error('Error:', error);
      });
    // setPoints([]);
    // setImgPoints([]);
    setPoints([]);
    setImgPoints([]);
    setWidthValue(0);
    updateGridCellContent(newCoordinates, widthValue, row, column, newWidth_pixel, widthValue, method, selectedValue,index);
    const imageElement = document.getElementById(`image-${index}`);
    if (imageElement) {
      // Set the src attribute to a placeholder or empty string initially
      imageElement.src = selectedImage;
      // Create a new Image object
      const img = new Image();
      img.src = selectedImage;
      // Set up an onload event handler for the new image
      img.onload = () => {
        imageElement.src = img.src;
      };

      img.onerror = () => {
        console.error('Failed to load the image');
      };
    }
    setModalOpen(false);
  }

  const widthCalibrationData = async () => {
    var value = null;
    const request_data = {
      "explorationId": localStorage.getItem("explorationId")
    }
    await axios.post('api/calbration/data', request_data)
      .then(response => {
        value = response.data.calibrationValue;
        setCalibrationValue(response.data.calibrationValue);
      })
      .catch(error => {
        console.error('Error:', error);
      });
    return value;
  }


  useEffect(() => {
    const img = new Image();
    img.src = selectedImage;
    img.onload = () => {
      setImageSize({ width: img.width, height: img.height });
      setCanvasReady(true);
    };
  }, [selectedImage]);

  useEffect(() => {
    if (!canvasReady) return;
    const canvas = canvasRef.current;
    if(canvas === null) return;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    imgPoints.forEach((coordinate) => {
      ctx.beginPath();
      ctx.moveTo(0, coordinate.yPixel);
      ctx.lineTo(canvas.width, coordinate.yPixel);
      ctx.lineWidth = 2;
      ctx.strokeStyle = "red";
      ctx.stroke();
    });
    const returnedCalibrationValue = widthCalibrationData();
    console.log("The returned calibration value " + returnedCalibrationValue);
    calculateWidth();
  }, [points, imgPoints]);


  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <div style={{ width: '70%', height: '70vh', overflowX: 'auto' }}>
        <Grid container spacing={2}>
          {gridItems.map((image, index) => (
            <Grid item xs={12 / gridSize} key={index}>
              <Paper
                style={{
                  cursor: 'pointer',
                  width: '100%',
                  padding: 10,
                  border: selectedImageIndex === index ? '2px solid #3f51b5' : 'none',
                  position: 'relative',
                }}
                onClick={() => handleImageClickGrid(image.image, index, image.coordinates, image.cluster_width, image.row, image.column, image.width_pixel, image.width_metric, image.method, image.patch_type)}

              >
                <div style={{ position: 'relative' }}>
                  <img
                    id={`image-${index}`}
                    src={image.image}
                    alt={`Image ${index}`}
                    loading="lazy"
                    // style={{ width: '100%' }}
                    style={{
                      width: '100%',
                    }}
                    onLoad={(e) => {
                      const img = e.target;
                      const imageWidth = img.naturalWidth;
                      const imageHeight = img.naturalHeight;
                      const canvasId = `canvas-${index}`;
                      const canvas = document.createElement('canvas');
                      canvas.id = canvasId; // Set the id attribute for the canvas element
                      canvas.width = imageWidth;
                      canvas.height = imageHeight;
                      const ctx = canvas.getContext('2d');
                      ctx.clearRect(0, 0, canvas.width, canvas.height);
                      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                      // Calculate y-coordinates based on image size
                      const yCoordinate1 = (image.coordinates[0] * imageHeight) / imageHeight;
                      const yCoordinate2 = (image.coordinates[1] * imageHeight) / imageHeight;

                      ctx.beginPath();
                      ctx.moveTo(0, yCoordinate1);
                      ctx.lineTo(canvas.width, yCoordinate1);
                      ctx.moveTo(0, yCoordinate2);
                      ctx.lineTo(canvas.width, yCoordinate2);
                      ctx.lineWidth = 2;
                      ctx.strokeStyle = 'red';
                      ctx.stroke();

                      img.src = canvas.toDataURL();
                    }}
                  />
                </div>
                <TextField
                  variant="standard"
                  margin="dense"
                  disabled
                  value={image.patch_type + " " + image.cluster_width}
                  style={{
                    background: 'white',
                    padding: '0px',
                    fontSize: '2px',
                    lineHeight: '1',
                  }}
                />
              </Paper>
            </Grid>
          ))}
        </Grid>
      </div>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{
                    width: "600px",
                    height: "800px",
                    position: "relative",
                    padding: 0,
                    margin: 0,
                  }}>
          <Paper style={{ padding: '20px', height: '90%', width: '90%', maxWidth: '600px' }}>
            {selectedImage && (
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={5}
                marginTop="2%"
              >
                <div
                  style={{
                    width: "500px",
                    height: "500px",
                    position: "relative",
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {
                    localStorage.getItem("previewImage") &&
                    <LazyLoad height={200} offset={100}>
                      <img
                        src={localStorage.getItem("previewImage")}
                        fit="contain"
                        ref={imageRef}
                        style={{ maxWidth: "100%", cursor: "crosshair" }}
                        onClick={handleImageClick}
                        onError={() => console.log("Error loading image")}
                        // onLoad={() => console.log("Image loaded successfully")}
                        onLoad={(e) => {
                          setImageSize({
                            width: e.target.width,
                            height: e.target.height,
                          });
                        }}
                      />
                      <canvas
                        ref={canvasRef}
                        width={imageSize.width}
                        height={imageSize.height}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          pointerEvents: "none",
                        }}
                      />
                    </LazyLoad>
                  }
                  <Button
                    component="label"
                    variant="contained"
                    style={{
                      width: "60ch",
                      height: "60px",
                      margin: "2%",
                    }}
                    onClick={handleClearCornerPoints}
                  >
                    {" "}
                    Clear Selected Edges
                  </Button>
                  <RadioGroup
                    aria-label="Options"
                    name="options"
                    value={selectedValue}
                    onChange={handleRadioClick}
                    style={{ marginTop: '10px' }}
                    row // Align radio buttons horizontally
                  >
                    <FormControlLabel
                      value="Marked"
                      control={<Radio />}
                      label="Marked"
                      labelPlacement="start" // Adjust label placement if necessary
                      component="div" // Use a div element for customization
                    />
                    <FormControlLabel
                      value="UnMarked"
                      control={<Radio />}
                      label="UnMarked"
                      labelPlacement="start"
                      component="div"
                    />
                    <FormControlLabel
                      value="Burnt"
                      control={<Radio />}
                      label="Burnt"
                      labelPlacement="start"
                      component="div"
                    />
                  </RadioGroup>
                  {showEnterValue && (
                    <TextField
                      variant="standard"
                      margin="normal"
                      value={widthValue}
                      style={{ marginTop: '10px' }}
                      disabled
                    />
                  )}
                  <Button variant="outlined" color="primary" style={{ marginTop: '70px' }} onClick={saveChangesClick}>
                    Save Changes
                  </Button>
                </div>
              </Stack>
            )}
          </Paper>
        </div>
      </Modal>
    </div>
  );
};

export default ImageGrid;

