import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Stack, Button, MenuItem } from "@mui/material";
import NavBarBottom from "../../components/NavBarBottom";
import ProgressBarTop from "../../components/ProgressBarTop"
import BinBarChart from "../../components/BinBarChart"
import MyScatterChart from "../../components/MyScatterChart"
import Box from '@mui/material/Box';
import PreviewIcon from '@mui/icons-material/Preview';
import { PROGRESS_BAR_TOTAL_STEPS } from "../../helper/colorExplorationParameters";
import { COLOR_MAXIMUM_ITERATION } from "../../helper/colorExplorationParameters";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ScatterChart,
    Scatter,
    Cell
} from "recharts";
import { Colors } from "../../styles/Colors";
import axios from "axios";
import {
    Paper,
    Radio,
    RadioGroup,
    FormControlLabel
} from '@mui/material';



const dataResolution = [0.7030733842402697, 0.07532929116860032, 0.0920691336505115, 0.0892791599035263, 0.05858944868668914, 0.01952981622889638, 0.7477129641920328, 0.10601900238543749, 0.6277440930716693, 0.07253931742161512, 0.6472739093005657, 0.6165841980837286, 0.6193741718307137, 0.06416939618065953, 0.44360582577064633, 0.07532929116860032, 0.06695936992764473, 0.4966153269633651, 0.07253931742161512, 0.04463957995176315, 0.04742955369874835, 0.5719446181319654, 0.6249541193246841, 0.07253931742161512, 0.08369921240955591, 0.13670871360227466, 0.07253931742161512, 0.382226403336972, 0.13670871360227466, 0.0892791599035263, 0.06974934367462993, 0.07811926491558552, 0.0948591073974967, 0.05858944868668914, 0.1004390548914671, 0.2008781097829342, 0.0892791599035263, 0.20645805727690458, 0.10322902863845229, 0.061379422433674335, 0.061379422433674335, 0.07253931742161512, 0.5217250906862319, 0.21203800477087498, 0.1004390548914671, 0.11159894987940788];


// TODO: convert color format is needed "color.lab2rgb"
const dataColor = [
    { x: 20, y: -15, color: '#3e74d3' },
    { x: 10, y: 27, color: '#82ca9d' },
    { x: -35, y: -45, color: '#FF5353' },
    { x: -40, y: 16, color: '#000000' },
    { x: 16, y: -9, color: '#8884d8' },
];

function ColorExplorationColorSummary() {

    const [iterationNumber, setIterationNumber] = useState(0);
    const [widthData, setWidthData] = useState([]);
    const [cumulativeWidthData, setCumulativeWidthData] = useState([]);
    const [colorData, setColorData] = useState([]);
    const [cumulativeColorData, setCumulativeColorData] = useState([]);
    const [widthResults, setWidthResults] = useState([]);
    const [colorResults, setColorResults] = useState([]);
    const [selectedValueWidth, setSelectedValueWidth] = useState('current');
    const [selectedValueColor, setSelectedValueColor] = useState('current');
    const [showMeasurementResults, setShowMeasurementResults] = useState(false);


    const navigate = useNavigate();

    let nextPageWithIteration = "/colorexplorationselectionprimaries";

    const handleWidthResultsOption = (event) => {
        setSelectedValueWidth(event.target.value);
        if (event.target.value === 'current') {
            setWidthResults(widthData);
        } else {
            setWidthResults(cumulativeWidthData);
        }
    };

    const handleColorResultsOption = (event) => {
        setSelectedValueColor(event.target.value);
        if (event.target.value === 'current') {
            setColorResults(colorData);
        } else {
            setColorResults(cumulativeColorData);
        }
    };

    const getWidthData = async () => {
        const request_data = {
            "explorationId": localStorage.getItem("explorationId"),
            "cumulative": false
        }
        const response = await axios.post('api/exploration/measurement/width/results', request_data);
        setWidthData(response.data.width);
        setWidthResults(response.data.width);
        console.log("results came");
        console.log(response.data.width);
        // axios.post('api/exploration/measurement/width/results', request_data)
        //     .then(response => {
        //         setWidthData(response.data.width);
        //         console.log("results came");
        //         console.log(widthData);
        //     })
        //     .catch(error => {
        //         console.error('Error:', error);
        //     });
    }

    const getCumulativeWidthData = async () => {
        const request_data = {
            "explorationId": localStorage.getItem("explorationId"),
            "cumulative": true
        }
        const response = await axios.post('api/exploration/measurement/width/results', request_data);
        setCumulativeWidthData(response.data.width);
        console.log("results came");
        console.log(response.data.width);
        // axios.post('api/exploration/measurement/width/results', request_data)
        //     .then(response => {
        //         setCumulativeWidthData(response.data.width);
        //         console.log("results came");
        //         console.log(cumulativeWidthData);
        //     })
        //     .catch(error => {
        //         console.error('Error:', error);
        //     });
    }

    const getIterationNumber = async () => {
        const request_data = {
            "explorationId": localStorage.getItem("explorationId")
        }
        const response = await axios.post('api/exploration/iteration/number', request_data);
        localStorage.setItem("iterationNumber", response.data.iterationNumber);
        console.log("results came");
        console.log(response.data.iterationNumber);
        // axios.post('api/exploration/iteration/number', request_data)
        //     .then(response => {
        //         localStorage.setItem("iterationNumber", response.data.iterationNumber);
        //         console.log("results came");
        //         console.log(response.data.iterationNumber);
        //     })
        //     .catch(error => {
        //         console.error('Error:', error);
        //     });
    }

    const getColorData = async () => {
        const request_data = {
            "explorationId": localStorage.getItem("explorationId"),
            "cumulative": false
        }
        const response = await axios.post('api/exploration/measurement/color/results', request_data);
        setColorData(response.data.color);
        console.log("results came");
        console.log(response.data.color);
        setColorResults(response.data.color);
        // axios.post('api/exploration/measurement/color/results', request_data)
        //     .then(response => {
        //         setColorData(response.data.color);
        //         console.log("results came");
        //         console.log(colorData);
        //     })
        //     .catch(error => {
        //         console.error('Error:', error);
        //     });
    }

    const getCumulativeColorData = async () => {
        const request_data = {
            "explorationId": localStorage.getItem("explorationId"),
            "cumulative": true
        }
        const response = await axios.post('api/exploration/measurement/color/results', request_data);
        setCumulativeColorData(response.data.color);
        console.log("results came");
        console.log(response.data.color);
        // axios.post('api/exploration/measurement/color/results', request_data)
        //     .then(response => {
        //         setCumulativeColorData(response.data.color);
        //         console.log("results came");
        //         console.log(cumulativeColorData);
        //     })
        //     .catch(error => {
        //         console.error('Error:', error);
        //     });
    }

    const onClickFetchMeasurementResults = () => {
        getIterationNumber();
        if (localStorage.getItem("iterationNumber") < (COLOR_MAXIMUM_ITERATION - 1)) {
            nextPageWithIteration = "/colorexplorationcolorresolution"; 
        }
        getWidthData();
        getCumulativeWidthData();
        getColorData();
        getCumulativeColorData();
        setShowMeasurementResults(true);
    }

    // TODO: note that useEffect runs after render()
    useEffect(() => {
        console.log("Running the use effect ");
        getIterationNumber();
        if (localStorage.getItem("iterationNumber") < (COLOR_MAXIMUM_ITERATION - 1)) {
            nextPageWithIteration = "/colorexplorationcolorresolution"; 
        }
        getWidthData();
        getCumulativeWidthData();
        getColorData();
        getCumulativeColorData();
        // setShowMeasurementResults(true);
    }, []);

    const nextPageClickFunc = (navigatePageName) => {
        navigate(navigatePageName)
    }

    return (
        <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={4}
            marginTop='2%'
        >

            <Box sx={{ width: '100%' }}>
                <ProgressBarTop currentStepNumber={5} totalStepNumber={PROGRESS_BAR_TOTAL_STEPS} progressBarName='Exploration' />
            </Box>

            <Box sx={{ width: '100%' }}>
                <ProgressBarTop currentStepNumber={3} totalStepNumber={3} progressBarName='Color - Summary' />
            </Box>

            {/* {
                !showMeasurementResults &&
                <Button variant="outlined" onClick={onClickFetchMeasurementResults}>
                    Show Measurement Results
                </Button>
            } */}
            {
                // showMeasurementResults &&
                <Stack
                direction='row'
                spacing={4}
            >

                <Stack direction='column'
                    justifyContent='flex-start'
                    alignItems='center'>
                    <TextField
                        disabled
                        label="Resolution performance"
                        sx={{
                            width: '60ch',
                            height: "60px",
                            margin: "2%",
                        }}
                        variant="filled"
                    />
                    <BinBarChart
                        width={500}
                        height={350}
                        data={widthResults}

                        margin={{
                            top: 10,
                            right: 30,
                            left: 20,
                            bottom: 5
                        }}
                    // barSize={20}
                    >
                        {/* <XAxis dataKey="width" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Bar dataKey="number" fill={Colors.blue} background={{ fill: Colors.white }} /> */}
                    </BinBarChart>
                    <RadioGroup
                        aria-label="Options"
                        name="options"
                        value={selectedValueWidth}
                        onChange={handleWidthResultsOption}
                        style={{ marginTop: '10px' }}
                        row // Align radio buttons horizontally
                    >
                        <FormControlLabel
                            value="current"
                            control={<Radio />}
                            label="current"
                            labelPlacement="start" // Adjust label placement if necessary
                            component="div" // Use a div element for customization
                        />
                        <FormControlLabel
                            value="cumulative"
                            control={<Radio />}
                            label="cumulative"
                            labelPlacement="start"
                            component="div"
                        />
                    </RadioGroup>

                </Stack>


                <Stack direction='column'
                    justifyContent='flex-start'
                    alignItems='center'>
                    <TextField
                        disabled
                        label="Color performance"
                        sx={{
                            width: '60ch',
                            height: "60px",
                            margin: "2%",
                        }}
                        variant="filled"
                    />
                    <MyScatterChart width={400} height={350} dataColor = {colorResults}
                        margin={{ top: 0, right: 0, left: 0, bottom: 5 }}
                    >
                        {/* <XAxis
                            type="number"
                            domain={[-60, 60]}
                            dataKey="x"
                            name="CIE-a*"
                            label={{ value: "CIE-a*", angle: 0, position: 'insideBottom', offset: -5 }}
                        />
                        <YAxis
                            type="number"
                            domain={[-60, 60]}
                            dataKey="y"
                            name="CIE-b*"
                            label={{ value: "CIE-b*", angle: -90, position: 'center' }}
                        />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Scatter data={dataColor}>
                            {dataColor.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color ?? "#8884d8"} />
                            ))}
                        </Scatter> */}

                    </MyScatterChart>

                    <RadioGroup
                        aria-label="Options"
                        name="options"
                        value={selectedValueColor}
                        onChange={handleColorResultsOption}
                        style={{ marginTop: '10px' }}
                        row // Align radio buttons horizontally
                    >
                        <FormControlLabel
                            value="current"
                            control={<Radio />}
                            label="current"
                            labelPlacement="start" // Adjust label placement if necessary
                            component="div" // Use a div element for customization
                        />
                        <FormControlLabel
                            value="cumulative"
                            control={<Radio />}
                            label="cumulative"
                            labelPlacement="start"
                            component="div"
                        />
                    </RadioGroup>

                </Stack>
            </Stack>
                
            }
            <NavBarBottom
                cancelPage={'/colorexplorationhome'}
                prevPage={'/colorexplorationcolorcolor'}
                nextPage={'/colorexplorationcolorresolution'}
                enableNextPage={false}
                nextPageClickFunc={nextPageClickFunc}
            >
            </NavBarBottom>
        </Stack>
    );
}
export default ColorExplorationColorSummary;