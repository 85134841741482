import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Stack, Button, MenuItem } from "@mui/material";
import NavBarBottom from "../../components/NavBarBottom";
import ProgressBarTop from "../../components/ProgressBarTop"
import Box from '@mui/material/Box';
import PreviewIcon from '@mui/icons-material/Preview';
import { PROGRESS_BAR_TOTAL_STEPS } from "../../helper/colorExplorationParameters";
import { COLOR_MAXIMUM_ITERATION } from "../../helper/colorExplorationParameters";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ScatterChart,
    Scatter,
    Cell
} from "recharts";
import { Colors } from "../../styles/Colors";

// TODO: convert color format is needed "color.lab2rgb"
const dataColor = [
    { x: 20, y: -15, color: '#3e74d3' },
    { x: 10, y: 27, color: '#82ca9d' },
    { x: -35, y: -45, color: '#FF5353' },
    { x: -40, y: 16, color: '#000000' },
    { x: 16, y: -9, color: '#8884d8' },
];

export default function ColorExplorationSelectionPrimaries() {
    const navigate = useNavigate();

    const nextPageClickFunc = (navigatePageName) => {
        navigate(navigatePageName)
    }

    return (
        <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={4}
            marginTop='2%'
        >

            <Box sx={{ width: '100%' }}>
                <ProgressBarTop currentStepNumber={6} totalStepNumber={PROGRESS_BAR_TOTAL_STEPS} progressBarName='Exploration' />
            </Box>

            <Box sx={{ width: '100%' }}>
                <ProgressBarTop currentStepNumber={1} totalStepNumber={2} progressBarName='Selection - Summary' />
            </Box>


            <Stack direction='column'
                justifyContent='flex-start'
                alignItems='center'>
                <TextField
                    disabled
                    label="Selected colors"
                    sx={{
                        width: '60ch',
                        height: "60px",
                        margin: "2%",
                    }}
                    variant="filled"
                />
                <ScatterChart width={400} height={350}
                    margin={{ top: 0, right: 0, left: 0, bottom: 5 }}
                >
                    <XAxis
                        type="number"
                        domain={[-60, 60]}
                        dataKey="x"
                        name="CIE-a*"
                        label={{ value: "CIE-a*", angle: 0, position: 'insideBottom', offset: -5 }}
                    />
                    <YAxis
                        type="number"
                        domain={[-60, 60]}
                        dataKey="y"
                        name="CIE-b*"
                        label={{ value: "CIE-b*", angle: -90, position: 'center' }}
                    />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Scatter data={dataColor}>
                        {dataColor.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color ?? "#8884d8"} />
                        ))}
                    </Scatter>

                </ScatterChart>

            </Stack>


            <NavBarBottom
                cancelPage={'/colorexplorationhome'}
                prevPage={'/colorexplorationcolorsummary'}
                nextPage={'/colorexplorationselectionverification'}
                enableNextPage={false}
                nextPageClickFunc={nextPageClickFunc}
            >
            </NavBarBottom>

        </Stack>
    );
}
