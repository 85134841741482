import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Colors } from "../styles/Colors";
import { useEffect } from "react";



const BinBarChart = ({ data }) => {
  // Function to calculate bins and counts
  const calculateBins = (widths) => {
    const binSize = 8; // Number of bins
    const max = Math.max(...widths);
    const min = Math.min(...widths);
    const binWidth = (max - min) / binSize;

    // Initialize bins
    const bins = Array.from({ length: binSize }, (_, index) => ({
      range: `${(min + index * binWidth).toFixed(2)}-${(min + (index + 1) * binWidth).toFixed(2)}`,
      count: 0,
    }));

    // Calculate counts in bins
    widths.forEach((width) => {
      const binIndex = Math.floor((width - min) / binWidth);
      if (binIndex >= 0 && binIndex < binSize) {
        bins[binIndex].count += 1;
      }
    });

    return bins;
  };

  useEffect(() => {
    console.log("Camer in the bin bar chart");
    console.log(data);
  }, []);

  const binsData = calculateBins(data);

  return (
    <BarChart width={600} height={400} data={binsData}>
      <XAxis dataKey="range" />
      <YAxis />
      <Tooltip />
      <Legend />
      <CartesianGrid strokeDasharray="3 3" />
      <Bar dataKey="count" fill={Colors.blue} background={{ fill: Colors.white }} />
      {/* <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="range" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="count" fill="#8884d8" /> */}
    </BarChart>
  );
};

export default BinBarChart;