import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { TextField, Stack, Button } from '@mui/material';
import { useNavigate } from "react-router-dom";



function Profile() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [userId,setuserId] = useState("");
  const [saveChanges,setSaveChanges] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
     axios
      .get("oraclase/auth/user/")
      .then((res) => {
        if (res.status === 200) {
          setFirstName(res.data.first_name);
          setLastName(res.data.last_name);
          setEmail(res.data.email);
          setuserId(res.data.pk)
        }
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get("api/user/meta/")
      .then((res) => {
        if (res.status === 200) {
          setCompanyName(res.data[0].company_name);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },[]);

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
    setSaveChanges(false)
  };
  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
    setSaveChanges(false)

  }
  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
    setSaveChanges(false)

  }
  const handleChangePassword = ()=> {
    navigate("/passwordchange");
  }
  let handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      first_name: firstName,
      last_name: lastName,
    };
    const meta_data = {
      company_name : companyName
    }
    await axios
    .put("api/users/"+userId+"/", data)
    .then((res) => {
      if (res.status === 200) {
        console.log("The details are updated");
      }
    })
    .catch((err) => {
      console.log('Error in login axios: ' + err);
    });

    await axios
    .put("api/user/meta/"+userId+"/",meta_data)
    .then((res) => {
      if (res.status === 200) {
        console.log("Changes the meta data")
      }
    })
    .catch((err) => {
      console.log('Error in login axios: ' + err);
    });
    setSaveChanges(true)
  }
  return (
    <Stack
      direction='column'
      justifyContent='center'
      alignItems='center'
      spacing={4}
      marginTop='2%'
    //alignSelf='center'
    >
      <TextField
        disabled
        id="oraclase"
        label='Your Profile Page'
        sx={{ width: '85ch' }}
        variant="filled"
      />
      <TextField
        required
        id="firstname"
        label="FirstName"
        placeholder="john.doe@gmail.com"
        value={firstName}
        onChange={handleFirstNameChange}
        type='text'
        sx={{ width: '85ch' }}
      />
      <TextField
        required
        id="lastname"
        label="LastName"
        value={lastName}
        onChange = {handleLastNameChange}
        type='text'
        sx={{ width: '85ch' }}
      />
      <TextField
        required
        id="email"
        label="email"
        placeholder="john.doe@gmail.com"
        value={email}
        type='email'
        sx={{ width: '85ch' }}
      />
      <TextField
        required
        id="companyname"
        label="CompanyName"
        value={companyName}
        onChange={handleCompanyNameChange}
        type='text'
        sx={{ width: '85ch' }}
      />
      <Button variant="outlined" onClick={handleChangePassword}>
        Change password
      </Button>

      <Button
        disabled = {saveChanges}
        variant="contained"
        // endIcon={<LoginIcon />}
        style={{ width:'88ch', height: "60px", marginLeft: "10px", marginTop: '2%' }}
        onClick={handleSubmit}
      >
        Save Changes
      </Button>
    </Stack>

  );
  // return (
  //   <div className="contact">
  //     <div className="rightSide">
  //       <h1>User Account</h1>
  //       <form id="contact-form">
  //         <label htmlFor="firstname">First Name</label>
  //         <input
  //           id="firstname"
  //           name="firstname"
  //           value={firstName}
  //           placeholder="Enter First name..."
  //           type="text"
  //         />
  //         <label htmlFor="lastname">Last Name</label>
  //         <input
  //           name="lastname"
  //           id="lastname"
  //           value={lastName}
  //           placeholder="Enter Last name..."
  //           type="text"
  //         />
  //         <label htmlFor="email">Email</label>
  //         <input
  //           name="email"
  //           id="email"
  //           value={email}
  //           placeholder="Enter email..."
  //           type="text"
  //           readOnly
  //         />
  //         <label htmlFor="companyname">Company Name</label>
  //         <input
  //           name="companyname"
  //           id="companyname"
  //           value={companyName}
  //           placeholder="Enter Company Name..."
  //           type="text"
  //         />
  //         <Link to="/passwordchange">Change Password</Link>
  //         <button type="submit">Save Changes</button>
  //       </form>
  //     </div>
  //   </div>
  // );
}

export default Profile;
