import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Stack, Button, MenuItem } from "@mui/material";
import NavBarBottom from "../../components/NavBarBottom";
import ProgressBarTop from "../../components/ProgressBarTop"
import Box from '@mui/material/Box';
import PreviewIcon from '@mui/icons-material/Preview';
import DownloadIcon from '@mui/icons-material/Download';
import { PROGRESS_BAR_TOTAL_STEPS } from "../../helper/colorExplorationParameters";
import Image from 'mui-image';
import axios from "axios";
import LoadingComponent from "../../components/LoadingComponent";


export default function ColorExplorationColorResolution() {

    const [microscopeColorImage, setMicroscopeColorImage] = useState("");
    const [microscopeColorImageData, setMicroscopeColorImageData] = useState("");
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const [imageObjectsDownloaded, setImageObjectsDownloaded] = useState([]);
    const [imageObjects, setImageObjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cornerPoints, setCornerPoints] = useState([]);
    const imageRef = useRef(null);
    const [clickedCoordinatesImage, setClickedCoordinatesImage] = useState([]);
    const [clickedCoordinates, setClickedCoordinates] = useState([]); // Step 1
    const [imageRect, setImageRect] = useState(null);
    const navigate = useNavigate();


    const handleImageClick = (event) => {
        const image = event.target;
        const imageRect = image.getBoundingClientRect();
        console.log("The event client X");
        console.log(event.clientX);
        console.log("The event client Y");
        console.log(event.clientY);
        const x = event.clientX - imageRect.left;
        const y = event.clientY - imageRect.top;
        setClickedCoordinates((prevCoordinates) => [...prevCoordinates, { x, y }]);
        console.log("The clicked co-ordinates that is shown in UI");
        console.log(x + " " + y);
        const x_a = Math.round(
            (event.clientX - imageRect.left) * (imageSize.width / imageRect.width)
        );
        const y_a = Math.round(
            (event.clientY - imageRect.top) * (imageSize.height / imageRect.height)
        );
        console.log("The clicked co-orfinates of the image");
        console.log(x_a + " " + y_a);
        // Store clicked coordinates in state
        const newCoordinatesArray = [...clickedCoordinatesImage, [y_a, x_a]];
        setClickedCoordinatesImage(newCoordinatesArray);
        const newCoordinatesArrayCornerPoints = [...cornerPoints, [x_a, y_a]];
        setCornerPoints(newCoordinatesArrayCornerPoints);
    };

    let handleClearCornerPoints = async (e) => {
        e.preventDefault();
        setClickedCoordinates([]);
        setClickedCoordinatesImage([]);
        setCornerPoints([]);
    }

    const handleMicroscopeColorImageChange = async (event) => {
        setMicroscopeColorImage(URL.createObjectURL(event.target.files[0]));
        setMicroscopeColorImageData(event.target.files[0]);
        //setMicroscopeColorImage(event.target.value);
        const formData = new FormData();
        formData.append('tiff_file', event.target.files[0]);
        const img = new window.Image();
        await axios.post('api/convert-to-png/', formData, {
            responseType: "blob",
        })
            .then(response => {
                // Set the PNG image data to state
                const blob = new Blob([response.data], { type: "image/png" });
                const blobUrl = URL.createObjectURL(blob);
                setMicroscopeColorImage(blobUrl);
                //setMicroscopeColorImageData(blob);
                img.src = blobUrl;
                img.onload = () => {
                    setImageSize({ width: img.naturalWidth, height: img.naturalHeight });
                };
                console.log("image size " + imageSize.width + " " + imageSize.height);
            })
            .catch(error => {
                console.error('Error:', error);
            });

        const formData1 = new FormData();
        formData1.append('file', event.target.files[0]);
        formData1.append('explorationId', localStorage.getItem("explorationId"))
        formData1.append('iterationNumber', localStorage.getItem("iterationNumber"))
        formData1.append('userId', localStorage.getItem("userId"))
        formData1.append('filename', "clusterImg.tif")
        try {
            const response = await axios.post('api/corner/points', formData1, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            // File upload successful, do something
            console.log("The corner points are");
            console.log(response.data.cornerPoints);
            setClickedCoordinates(response.data.cornerPoints);
            if (imageRef.current) {
                console.log("came inside this if as it is not null");
                console.log(imageRef.current)
                const imageRect = imageRef.current.getBoundingClientRect();
                console.log("image rect ");
                console.log(imageRect);
                console.log(imageSize.width);
                console.log(imageSize.height);
                const updatedCoordinatesArray = response.data.cornerPoints.map(
                    (coordinate) => {
                        const x = (coordinate[0] / img.naturalWidth) * imageRef.current.clientWidth;
                        const y = (coordinate[1] / img.naturalHeight) * imageRef.current.clientHeight;
                        return { x, y };
                    }
                );
                console.log("updated the corner points that got from the backend");
                console.log(updatedCoordinatesArray);
                setClickedCoordinates(updatedCoordinatesArray);
            }
            else {
                console.log("The image ref is still null");
            }
            setCornerPoints(response.data.cornerPoints);
        } catch (error) {
            // Handle error
            console.log("Error while uploading the calibration file")
        }
    };

    // TODO: add function to download marking files
    const handleDownloadMarkingFiles = () => {
        console.log(' in download marking files.')
    }
    const prepare_image_data = async (downloaded_data) => {
        console.log(downloaded_data.length);
        console.log(downloaded_data);

        try {
            const imageArray = await Promise.all(downloaded_data.map(async (image_data) => {
                const image = await download_patch_image(image_data.cluster_patch_image);
                const coordinates = [image_data.edge_a_location, image_data.edge_b_location];
                const cluster_width = image_data.cluster_width;
                const row = image_data.row;
                const column = image_data.column;
                const width_pixel = image_data.width_pixel;
                const width_metric = image_data.width_metric;
                const method = image_data.method;
                const patch_type = image_data.patch_type;

                return {
                    image,
                    coordinates,
                    cluster_width,
                    row,
                    column,
                    width_pixel,
                    width_metric,
                    method,
                    patch_type
                };
            }));

            setImageObjectsDownloaded(imageArray);
            localStorage.setItem("imageObjectsDownloaded", JSON.stringify(imageArray));
            // setShowGrid(true);
            // console.log("The moment before storing the images downloaded")
            // console.log(JSON.stringify(imageArray));
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    };


    const download_patch_image = async (cloud_file_path) => {
        console.log("downloading this image");
        console.log(cloud_file_path);

        try {
            const data = {
                fileDestination: cloud_file_path
            };
            const response = await axios.post('api/patch/file/get', data, {
                responseType: 'blob',
            });
            const fileUrl = URL.createObjectURL(response.data);
            return fileUrl;
        } catch (error) {
            console.log('Error:', error);
            throw error; // Re-throw the error to be caught by the caller
        }
    };

    // TODO: if file preview available for color --> activate Next button. After Next button pressed, the images get uploaded to cloud.
    const nextPageClickFunc = async (navigatePageName) => {
        setLoading(true);
        localStorage.setItem("microscopeColorImage", microscopeColorImage);
        const formData = new FormData();
        formData.append('file', microscopeColorImageData);
        var fileDestination = "users/" + localStorage.getItem("userId") + "/explorations/" + localStorage.getItem("explorationId") + "/Q" + localStorage.getItem("iterationNumber") + "/clusterImg.tif"
        formData.append('fileDestination', fileDestination)
        // formData.append('explorationId', localStorage.getItem("explorationId"))
        // formData.append('iterationNumber', localStorage.getItem("iterationNumber"))
        // formData.append('userId', localStorage.getItem("userId"))
        try {
            const response = await axios.post('api/upload/file-to-cloud', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            // File upload successful, do something
            // console.log("The corner points are");
            // console.log(response.data.cornerPoints);
            // localStorage.setItem("cornerPointsResolution", response.data.cornerPoints);
            // setCornerPoints(response.data.cornerPoints);

        } catch (error) {
            // Handle error
            console.log("Error while uploading the calibration file")
        }

        const fetchData = async () => {
            const formData = new FormData();
            const request_data = {
                "imageToQuantify": "users/" + localStorage.getItem("userId") + "/explorations/" + localStorage.getItem("explorationId") + "/Q" + localStorage.getItem("iterationNumber") + "/clusterImg.tif",
                "iterationNumber": localStorage.getItem("iterationNumber"),
                "explorationId": localStorage.getItem("explorationId"),
                "cornerPoints" : cornerPoints
            }
            await axios.post('api/exploration/measure/width', request_data)
                .then(response => {
                    setImageObjects(response.data.results);
                    // prepare_image_data(response.data.results);
                    localStorage.setItem("imageObjectsDownloaded", JSON.stringify(response.data.results));
                    console.log("printing the stored content");
                    console.log(JSON.parse(localStorage.getItem("imageObjectsDownloaded")))
                    setLoading(false);
                    navigate(navigatePageName)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        };
        await fetchData();
    }
    const handleDownloadClick = async () => {
        const data = {
            "explorationId": localStorage.getItem("explorationId"),
            "patchType": "SINGLE_CLUSTER"
        }
        const response = await axios.post("api/download/marking/file", data, {
            responseType: "blob",
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        var filename;
        if(localStorage.getItem("iterationNumber") == 0){
            filename = 'marking-files-cluster_p0.zip'
        }
        else{
            const fname = 'marking-files-cluster_q'
            const fileExtension = '.zip'
            filename = `${fname}_${localStorage.getItem("iterationNumber")-1}${fileExtension}`;
        }
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    };

    const getIterationNumber = async () => {
        const request_data = {
            "explorationId": localStorage.getItem("explorationId")
        }
        axios.post('api/exploration/iteration/number', request_data)
            .then(response => {
                localStorage.setItem("iterationNumber", response.data.iterationNumber)
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        // setMicroscopeColorImage(localStorage.getItem("microscopeColorImage"));
        getIterationNumber();
    }, []);

    return (
        <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={4}
            marginTop='2%'
        >

            <Box sx={{ width: '100%' }}>
                <ProgressBarTop currentStepNumber={5} totalStepNumber={PROGRESS_BAR_TOTAL_STEPS} progressBarName='Exploration' />
            </Box>

            <Box sx={{ width: '100%' }}>
                <ProgressBarTop currentStepNumber={1} totalStepNumber={3} progressBarName='Color - Resolution' />
            </Box>
            {loading ? (
                <LoadingComponent />
            ) : (

                <Stack
                    direction='row'
                    spacing={4}

                >
                    <Stack direction='column'
                        justifyContent='flex-start'
                        alignItems='center'>
                        <Button
                            component="label"
                            variant="contained"
                            startIcon={<DownloadIcon />}
                            onClick={handleDownloadClick}
                            style={{
                                width: "60ch",
                                height: "60px",
                                margin: "2%",
                            }}
                        > Download marking files and use in laser
                        </Button>
                    </Stack>


                    <Stack direction='column'
                        justifyContent='flex-start'
                        alignItems='center'>
                        <Button
                            component="label"
                            variant="contained"
                            startIcon={<PreviewIcon />}
                            style={{
                                width: "60ch",
                                height: "60px",
                                margin: "2%",
                            }}
                        > choose microscope image of marked plate
                            <input style={{ display: 'none' }} type="file" onChange={handleMicroscopeColorImageChange} />
                        </Button>
                        <div
                            style={{
                                width: "500px",
                                height: "500px",
                                position: "relative",
                                padding: 0,
                                margin: 0,
                            }}
                        >
                            {
                                microscopeColorImage &&
                                <img
                                    src={microscopeColorImage}
                                    fit="contain"
                                    ref={imageRef}
                                    style={{ maxWidth: "100%", cursor: "crosshair" }}
                                    onClick={handleImageClick}
                                    onError={() => console.log("Error loading image")}
                                    onLoad={() => console.log("Image loaded successfully")}
                                />

                            }

                            {clickedCoordinates.map((coordinate, index) => (
                                <div
                                    key={index}
                                    style={{
                                        position: "absolute",
                                        top: coordinate.y,
                                        left: coordinate.x,
                                        width: "10px",
                                        height: "10px",
                                        borderRadius: "50%",
                                        backgroundColor: "red",
                                    }}
                                />
                            ))}
                            <Button
                                component="label"
                                variant="contained"
                                style={{
                                    width: "60ch",
                                    height: "60px",
                                    margin: "2%",
                                }}
                                onClick={handleClearCornerPoints}
                            >
                                {" "}
                                Clear Selected Corner Points
                            </Button>
                        </div>
                    </Stack>
                </Stack>
            )}


            <NavBarBottom
                cancelPage={'/colorexplorationhome'}
                prevPage={'/colorexplorationhome'}
                nextPage={'/colorexplorationmeasurewidth'}
                enableNextPage={(microscopeColorImage != null && microscopeColorImage.length > 0 && cornerPoints.length == 4) ? false : true}
                nextPageClickFunc={nextPageClickFunc}
            >
            </NavBarBottom>

        </Stack>
    );
}