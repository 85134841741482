import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function About() {
  return (
    <Box sx={{ width: '100%' }}>
      <Typography ml={5} mr={5} mt={5} fontSize={25} fontFamily={'calibri'}>
        This platform is developed by Oraclase. Our software finds the optimal laser process parameters that are repeatable and robust across different substrate and laser systems.
        Our patented technology is based on artificial intelligence.
        The initial software modules are: color explorer, image reproduction, and roughness explorer.
      </Typography>
      <Typography ml={5} mr={5} mt={5} fontSize={25} fontFamily={'calibri'}>
        Oraclase company is located in Saarbrucken Germany.
      </Typography>
    </Box>
  )
}

export default About
