import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { TextField, Stack, Button } from '@mui/material';
import { Send, ArrowBackIos } from "@mui/icons-material";

function PasswordForgotten() {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleBackToLogin = () => {
        navigate('/login')
    }

    const handleSendemail = async () => {
        const data = {
            email: email
        }
        await axios
            .post("account/api/password_reset/", data)
            .then((res) => {
                if (res.status === 200) {
                    alert("We sent you an email to resent your password.")
                    navigate('/login')
                }
            })
            .catch((err) => {
                console.log("Error in password forgotten for sending message: " + err);
            });
    };


    return (
        <Stack
            display='flex'
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={4}
            marginTop='2%'
        >
            <TextField
                disabled
                id="password-change-page"
                label='Enter your email and we send you an email to reset your password.'
                sx={{ width: '85ch' }}
                variant="filled"
            />
            <TextField
                required
                id="email"
                label="Email"
                placeholder="john.doe@gmail.com"
                onChange={handleEmailChange}
                value={email}
                type='email'
                sx={{ width: '85ch' }}
            />

            <Stack
                direction='row'
                spacing={4}
            >
                <Button
                    variant="contained"
                    startIcon={<ArrowBackIos />}
                    style={{ width: '43ch', height: "60px", marginLeft: "10px", marginTop: '2%' }}
                    onClick={handleBackToLogin}
                >
                    Back to login
                </Button>
                <Button
                    variant="contained"
                    startIcon={<Send />}
                    style={{ width: '43ch', height: "60px", marginLeft: "10px", marginTop: '2%' }}
                    onClick={handleSendemail}
                >
                    Send email
                </Button>
            </Stack>
        </Stack>

    )
}

export default PasswordForgotten;
