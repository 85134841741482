import React, { useEffect } from "react";
import { useState } from "react";
import { TextField, Stack, Button, MenuItem } from "@mui/material";
import NavBarBottom from "../../components/NavBarBottom";
import ProgressBarTop from "../../components/ProgressBarTop"
import Box from '@mui/material/Box';
import { PROGRESS_BAR_TOTAL_STEPS } from "../../helper/colorExplorationParameters";
import { useNavigate } from "react-router-dom";
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import axios from "axios";


export default function ColorExplorationSetupName() {

    const [explorationName, setExplorationName] = useState("");
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setExplorationName("")
        setOpen(false);
    };

    const handleExplorationNameChange = (event) => {
        setExplorationName(event.target.value);
    };
    const nextPageClickFunc = async (navigatePageName) => {
        var proceed = false
        await axios
            .get(
                "api/color/explorer/exploration/?name=" + explorationName
            )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.length === 1) {
                        proceed = false
                    }
                    if (res.data.length === 0) {
                        proceed = true
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
        if (!proceed) {
            handleClickOpen()
        }
        else {
            localStorage.setItem("explorationName", explorationName);
            navigate(navigatePageName);
        }
    }
    useEffect(() => {
        setExplorationName(localStorage.getItem("explorationName"))
    }, []);

    return (
        <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={4}
            marginTop='2%'
        >

            <Box sx={{ width: '100%' }}>
                <ProgressBarTop currentStepNumber={1} totalStepNumber={PROGRESS_BAR_TOTAL_STEPS} progressBarName='Exploration' />
            </Box>

            <TextField
                disabled
                label="Insert a name for the new exploration"
                sx={{ width: '85ch' }}
                variant="filled"
            />

            <TextField
                required
                id="explorationname"
                label="Exploration Name"
                placeholder="insert exploration name"
                onChange={handleExplorationNameChange}
                value={explorationName}
                type='text'
                sx={{ width: '85ch' }}
            />
            <div>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Error!</DialogTitle>
                    <DialogContent sx={{ fontFamily: 'arial', marginTop: '2%', marginBottom: '2%' }}>
                        The Exploration name already exists. Please give another name
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClose}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            <NavBarBottom cancelPage={'/colorexplorationhome'} prevPage={'/colorexplorationhome'} nextPage={'/colorexplorationobjectiveselect'} enableNextPage={(explorationName != null && explorationName.length > 0) ? false : true} nextPageClickFunc={nextPageClickFunc}></NavBarBottom>

        </Stack>
    );
}