import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import NavBarTop from "./components/NavBarTop";
import NodeListPage from "./pages/NodeListPage";
import HomePage from "./pages/Home";
import AboutPage from "./pages/About";
import ContactPage from "./pages/Contact";
import LoginPage from "./pages/Login";
import ProfilePage from "./pages/Profile";
import PasswordChange from "./pages/PasswordChange";
import PasswordForgotten from "./pages/PasswordForgotten";
import ColorExplorationHome from "./pages/colorExploration/ColorExplorationHome";
import ColorExplorationSetupName from "./pages/colorExploration/ColorExplorationSetupName";
import ColorExplorationSetupMicroscopicCalibrationSelect from "./pages/colorExploration/ColorExplorationSetupMicroscopicCalibrationSelect";
import ColorExplorationMicroscopicCalibrationName from "./pages/colorExploration/ColorExplorationMicroscopicCalibrationName";
import ColorExplorationSetupLaserSelect from "./pages/colorExploration/ColorExplorationSetupLaserSelect";
import ColorExplorationSetupSubstrateSelect from "./pages/colorExploration/ColorExplorationSetupSubstrateSelect";
import ColorExplorationSetupSummary from "./pages/colorExploration/ColorExplorationSetupSummary";
import ProtectedRoutes from "./components/ProtectedRoutes";
import ColorExplorationCalibrationMicroscope from "./pages/colorExploration/ColorExplorationCalibrationMicroscope";
import ColorExplorationCalibrationCamera from "./pages/colorExploration/ColorExplorationCalibrationCamera";
import ColorExplorationColorResolution from "./pages/colorExploration/ColorExplorationColorResolution"
import ColorExplorationColorColor from "./pages/colorExploration/ColorExplorationColorColor";
import ColorExplorationColorSummary from "./pages/colorExploration/ColorExplorationColorSummary";
import ColorExplorationSelectionPrimaries from "./pages/colorExploration/ColorExplorationSelectionPrimaries";
import ColorExplorationSelectionVerification from "./pages/colorExploration/ColorExplorationSelectionVerification";
import ColorExplorationSetupSubstrateCreate from "./pages/colorExploration/ColorExplorationSetupSubstrateCreate";
import ColorExplorationSetupCameraCalibrationSelect from "./pages/colorExploration/ColorExplorationSetupCameraCalibrationSelect";
import ColorExplorationCameraCalibrationName from "./pages/colorExploration/ColorExplorationCameraCalibrationName";
import ColorExplorationMeasureWidth from "./pages/colorExploration/ColorExplorationMeasureWidth";
import ColorExplorationObjectiveSelect from "./pages/colorExploration/ColorExplorationObjectiveSelect";
import ColorSelectionHome from "./pages/colorSelection/ColorSelectionHome";
import ColorSelectionObjective from "./pages/colorSelection/ColorSelectionObjective";
import ColorSelectionSelectExplorations from "./pages/colorSelection/ColorSelectionSelectExplorations";
import ColorSelectionSetUpName from "./pages/colorSelection/ColorSelectionSetUpName";


function App() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Oraclase</title>
        <link rel="canonical" href="http://mysite.com/example" />
        <meta name="description" content="Oraclase Application" />
      </Helmet>
      <Router>
        <NavBarTop></NavBarTop>
        <Routes>
          <Route path="/" exact element={<HomePage></HomePage>}></Route>
          <Route path="/about" exact element={<AboutPage></AboutPage>}></Route>
          <Route
            path="/contact"
            exact
            element={<ContactPage></ContactPage>}
          ></Route>
          <Route path="/login" exact element={<LoginPage></LoginPage>}></Route>
          <Route
            path="/passwordforgotten"
            exact
            element={<PasswordForgotten></PasswordForgotten>}
          ></Route>
          <Route
            path="/passwordforgotten"
            exact
            element={<PasswordForgotten></PasswordForgotten>}
          ></Route>
          <Route element={<ProtectedRoutes />}>
            <Route
              path="/colorexplorationcolorresolution"
              exact
              element={<ColorExplorationColorResolution></ColorExplorationColorResolution>}
            ></Route>
            <Route
              path="/colorexplorationcolorcolor"
              exact
              element={<ColorExplorationColorColor></ColorExplorationColorColor>}
            ></Route>
            <Route
              path="/colorexplorationcolorsummary"
              exact
              element={<ColorExplorationColorSummary></ColorExplorationColorSummary>}
            ></Route>
            <Route
              path="/colorexplorationsetupsubstratecreate"
              exact
              element={<ColorExplorationSetupSubstrateCreate></ColorExplorationSetupSubstrateCreate>}
            ></Route>
             <Route
              path="/colorexplorationsetupcameracalibrationselect"
              exact
              element={<ColorExplorationSetupCameraCalibrationSelect></ColorExplorationSetupCameraCalibrationSelect>}
            ></Route>
            <Route
              path="/colorexplorationcameracalibrationname"
              exact
              element={<ColorExplorationCameraCalibrationName></ColorExplorationCameraCalibrationName>}
            ></Route>
            <Route
              path="/colorexplorationmeasurewidth"
              exact
              element={<ColorExplorationMeasureWidth></ColorExplorationMeasureWidth>}
            ></Route>
            <Route
              path="/profile"
              exact
              element={<ProfilePage></ProfilePage>}
            ></Route>
            <Route
              path="/passwordchange"
              exact
              element={<PasswordChange></PasswordChange>}
            ></Route>
            <Route
              path="/colorexplorationhome"
              exact
              element={<ColorExplorationHome></ColorExplorationHome>}
            ></Route>
            <Route
              path="/colorexplorationsetupname"
              exact
              element={<ColorExplorationSetupName></ColorExplorationSetupName>}
            ></Route>
            <Route
              path="/colorexplorationsetupmicroscopiccalibrationselect"
              exact
              element={<ColorExplorationSetupMicroscopicCalibrationSelect></ColorExplorationSetupMicroscopicCalibrationSelect>}
            ></Route>
            <Route
              path="/colorexplorationmicroscopiccalibrationname"
              exact
              element={<ColorExplorationMicroscopicCalibrationName></ColorExplorationMicroscopicCalibrationName>}
            ></Route>
            <Route
              path="/colorexplorationsetuplaserselect"
              exact
              element={<ColorExplorationSetupLaserSelect></ColorExplorationSetupLaserSelect>}
            ></Route>
             <Route
              path="/colorexplorationobjectiveselect"
              exact
              element={<ColorExplorationObjectiveSelect></ColorExplorationObjectiveSelect>}
            ></Route>
            <Route
              path="/colorexplorationsetupsubstrateselect"
              exact
              element={<ColorExplorationSetupSubstrateSelect></ColorExplorationSetupSubstrateSelect>}
            ></Route>
            <Route
              path="/colorexplorationsetupsummary"
              exact
              element={<ColorExplorationSetupSummary></ColorExplorationSetupSummary>}
            ></Route>
            <Route
              path="/colorexplorationcalibrationmicroscope"
              exact
              element={<ColorExplorationCalibrationMicroscope></ColorExplorationCalibrationMicroscope>}
            ></Route>
            <Route
              path="/colorexplorationcalibrationcamera"
              exact
              element={<ColorExplorationCalibrationCamera></ColorExplorationCalibrationCamera>}
            ></Route>
            <Route
              path="/colorexplorationselectionprimaries"
              exact
              element={<ColorExplorationSelectionPrimaries></ColorExplorationSelectionPrimaries>}
            ></Route>
            <Route
              path="/colorexplorationselectionverification"
              exact
              element={<ColorExplorationSelectionVerification></ColorExplorationSelectionVerification>}
            ></Route>
          </Route>
          <Route
              path="/colorselectionhome"
              exact
              element={<ColorSelectionHome></ColorSelectionHome>}
            ></Route>
            <Route
              path="/colorselectionobjective"
              exact
              element={<ColorSelectionObjective></ColorSelectionObjective>}
            ></Route>
            <Route
              path="/colorselectionselectexplorations"
              exact
              element={<ColorSelectionSelectExplorations></ColorSelectionSelectExplorations>}
            ></Route>
            <Route
              path="/colorselectionsetupname"
              exact
              element={<ColorSelectionSetUpName></ColorSelectionSetUpName>}
            ></Route>
        </Routes>
      </Router>

      {/* <BrowserRouter>
          <Routes>
            <Route path="/" exact element={<NodeListPage />} />
          </Routes>
        </BrowserRouter> */}
    </div>
  );
}

export default App;
