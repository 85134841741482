import React, { useEffect } from "react";
import { useState } from "react";
import { TextField, Stack, Button } from "@mui/material";
import NavBarBottom from "../../components/NavBarBottom";
import ProgressBarTop from "../../components/ProgressBarTop";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import { PROGRESS_BAR_TOTAL_STEPS } from "../../helper/colorExplorationParameters";
import axios from "axios";



export default function ColorExplorationSetupSubstrateSelect() {
  const navigate = useNavigate();
  const [substrateSelection, setSubstrateSelection] = useState("");
  const[substrateNames,setSubstrateNames] = useState([]);

  const handleSubstrateSelection = (event) => {
    setSubstrateSelection(event.target.value);
    localStorage.setItem("materialId", event.target.value);

  };

  const nextPageClickFunc = (navigatePageName)=> {
    navigate(navigatePageName)
}
  useEffect(() => {
    axios
      .get(
        "api/color/explorer/material/?auth_user_id=" + localStorage.getItem("userId")
      )
      .then((res) => {
        if (res.status === 200) {
            const tempSubstrateNames = []
            for(let i=0;i<res.data.length;i++){
                tempSubstrateNames[i] = {
                    "id" : res.data[i].id,
                    "name" : res.data[i].material_name
                }
            }
            setSubstrateNames(tempSubstrateNames)
        }
      })
      .catch((err) => {
        console.log(err);
      });
      setSubstrateSelection(localStorage.getItem("materialId"))  
  }
  , []);
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={4}
      marginTop="2%"
    >
      <Box sx={{ width: "100%" }}>
        <ProgressBarTop
          currentStepNumber={3}
          totalStepNumber={PROGRESS_BAR_TOTAL_STEPS}
          progressBarName="Exploration"
        />
      </Box>

      <Stack direction="row" spacing={4}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <TextField
            disabled
            label="create a new substrate"
            sx={{ width: "85ch" }}
            variant="filled"
          />
          <Button
            variant="contained"
            style={{
              width: "43ch",
              height: "60px",
              marginLeft: "10px",
              marginTop: "2%",
            }}
            onClick={() => navigate("/colorexplorationsetupsubstratecreate")}
          >
            New substrate
          </Button>
        </Stack>

        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <TextField
            disabled
            label="Select from previous substrate"
            sx={{ width: "85ch" }}
            variant="filled"
          />
          <FormControl sx={{ m: 1, minWidth: 250 }}>
            <InputLabel id="demo-simple-select-helper-label">
              substrate name
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={substrateSelection}
              label="substrate Name"
              onChange={handleSubstrateSelection}
            >
              {substrateNames.map((materialName) => (
                <MenuItem value={materialName.id}>{materialName.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Stack>

      <NavBarBottom
        cancelPage={"/colorexplorationhome"}
        prevPage={"/colorexplorationsetuplaserselect"}
        nextPage={"/colorexplorationsetupmicroscopiccalibrationselect"}
        enableNextPage={
          localStorage.getItem("materialId") != null &&
          localStorage.getItem("materialId").length > 0
            ? false
            : true
        }
        nextPageClickFunc={nextPageClickFunc}
      ></NavBarBottom>
    </Stack>
  );
}
