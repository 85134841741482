import React from "react";
import { TextField, Stack } from "@mui/material";
import ProgressBarTop from "../../components/ProgressBarTop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import StartIcon from "@mui/icons-material/Start";
import { Colors } from "../../styles/Colors";
import { useNavigate } from "react-router-dom";
import { PROGRESS_BAR_TOTAL_STEPS } from "../../helper/colorExplorationParameters";
import axios from "axios";

export default function ColorExplorationSetupSummary() {
  const navigate = useNavigate();
  const startExplorationClick = async () => {
    var material_id = null;
    if(localStorage.getItem("materialId")!=null && localStorage.getItem("materialId").length > 0){
      material_id = localStorage.getItem("materialId")
    }
    else{
      const material_data = {
        auth_user: localStorage.getItem("userId"),
        material_name: localStorage.getItem("materialName"),
        length: localStorage.getItem("length"),
        width: localStorage.getItem("widthValue"),
        thickness: localStorage.getItem("thicknessValue"),
        material_type: localStorage.getItem("materialName"),
      };
      await axios
        .post("api/color/explorer/material/", material_data)
        .then((res) => {
          if (res.status === 201) {
            material_id = res.data.id;
          }
        })
        .catch((err) => {
          console.log("Error in persisting material deatails" + err);
        });
    }
    const exploration_data = {
      name: localStorage.getItem("explorationName"),
      iteration_count: 10,
      width_calibration :localStorage.getItem("microscopicCalibrationId"),
      color_metric_calibration : localStorage.getItem("cameraCalibrationId"),
      auth_user: localStorage.getItem("userId"),
      laser_device: localStorage.getItem("laser"),
      material: material_id,
      exploration_objective : localStorage.getItem("explorationObjective"),
      status: "PENDING",
    };
    await axios
      .post("api/color/explorer/exploration/", exploration_data)
      .then((res) => {
        if (res.status === 201) {
          localStorage.setItem("explorationId", res.data.id);
        }
      })
      .catch((err) => { 
        console.log("Error in persisting calibration deatails" + err);
      });
    const exploration_start_data = {
      "explorationId" : localStorage.getItem("explorationId"),
      "iterationNumber" : 0
    }
    await axios
      .post("api/exploration/initial/step", exploration_start_data)
      .then((res) => {
        if (res.status === 201) {
          
        }
      })
      .catch((err) => { 
        console.log("Error in persisting calibration deatails" + err);
      });
    localStorage.removeItem("explorationName");
    localStorage.removeItem("laser");
    localStorage.removeItem("materialName");
    localStorage.removeItem("materialId")
    localStorage.removeItem("widthValue");
    localStorage.removeItem("thicknessValue");
    localStorage.removeItem("cameraColorImage");
    localStorage.removeItem("cameraWhiteImage");
    localStorage.removeItem("microscopeImage");
    localStorage.removeItem("length");
    localStorage.removeItem("microscopicCalibrationName");
    localStorage.removeItem("cameraCalibrationName");
    localStorage.removeItem("microscopicCalibrationId");
    localStorage.removeItem("cameraCalibrationId");
    localStorage.removeItem("microscopeColorImage");
    navigate("/colorexplorationcolorresolution");
  };
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={4}
      marginTop="2%"
    >
      <Box sx={{ width: "100%" }}>
        <ProgressBarTop
          currentStepNumber={5}
          totalStepNumber={PROGRESS_BAR_TOTAL_STEPS}
          progressBarName="Exploration"
        />
      </Box>

      <TextField
        disabled
        label="The setup is completed for color exploration. Let’s start the exploration!"
        sx={{ width: "85ch" }}
        variant="filled"
      />

      <Stack
        position="absolute"
        bottom="0"
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        alignSelf="flex-end"
        style={{
          backgroundColor: Colors.navbarBottomBackground,
          height: "100px",
          width: "100%",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          marginRight={"10px"}
        >
          <Button
            variant="contained"
            startIcon={<StartIcon />}
            style={{ height: "40px", marginLeft: "10px" }}
            onClick={startExplorationClick}
          >
            Start exploration
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
