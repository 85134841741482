import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const useStyles = makeStyles({
  root: {
    minWidth: 500,
    minHeight: 200
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function CustomCard({ image, title, description, navigateTo,navigatePageName }) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNavigateTo = () => {
    axios
      .get("api/user/module/access/?auth_user_id=" + localStorage.getItem("userId"))
      .then((res) => {
        if (res.status === 200) {
          var haveAccess = false
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].module_id == navigateTo) {
              haveAccess = true
              console.log(navigatePageName);
              navigate("/"+navigatePageName);
            }
          }
          if (haveAccess === false) {
            handleClickOpen()
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  return (
    <div>
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <CardMedia
            component="img"
            height="200"
            image={image}
            alt={title}
            sx={{ objectFit: "contain" }}
          ></CardMedia>
          <Typography variant="h5" component="h2">
            {title}
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
          </Typography>
          <Typography variant="body2" component="p">
            {description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleNavigateTo}>
            Access
          </Button>
        </CardActions>
      </Card>

      <div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Permission Denied!</DialogTitle>
          <DialogContent sx={{fontFamily:'arial', marginTop:'2%', marginBottom:'2%'}}>
            You don't have access to this module, please contact us to get permission.
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClose}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      
    </div>
  );
}
