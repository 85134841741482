import React, { useEffect } from "react";
import { useState } from "react";
import { TextField, Stack, Button } from "@mui/material";
import NavBarBottom from "../../components/NavBarBottom";
import ProgressBarTop from "../../components/ProgressBarTop"
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useNavigate } from "react-router-dom";
import { PROGRESS_BAR_TOTAL_STEPS } from "../../helper/colorExplorationParameters";
import axios from "axios";



export default function ColorExplorationObjectiveSelect() {

    const navigate = useNavigate();
    const [explorationObjectiveSelection, setExplorationObjectiveSelection] = useState("");
    const [explorationObjectives, setExplorationObjectives] = useState([]);
    const handleExplorationObjectiveSelection = (event) => {
        console.log(event.target.value)
        setExplorationObjectiveSelection(event.target.value);
    };
    const nextPageClickFunc = (navigatePageName) => {
        localStorage.setItem("explorationObjective", explorationObjectiveSelection);
        navigate(navigatePageName)
    }
    useEffect(() => {
        axios
        .get("api/color/explorer/exploration/objective")
        .then((res) => {
            if (Array.isArray(res.data) && res.data.length > 0) {
                const objectives = res.data.map((item) => ({
                    id: item.id,
                    name: item.name,
                  }));
                setExplorationObjectives(objectives);
            }
        })
        .catch((err) => {
          console.log("Error in handleSubmit at axios: " + err);
        });
        setExplorationObjectiveSelection(localStorage.getItem("explorationObjective"))
    }, []);

    return (
        <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={4}
            marginTop='2%'
        >

            <Box sx={{ width: '100%' }}>
                <ProgressBarTop currentStepNumber={2} totalStepNumber={PROGRESS_BAR_TOTAL_STEPS} progressBarName='Exploration' />
            </Box>

            <Stack
                direction='column'
                justifyContent='center'
                alignItems='center'
                spacing={4}
            >
                <TextField
                    disabled
                    label="Select from available Exploration Objectives"
                    sx={{ width: '85ch' }}
                    variant="filled"
                />
                <FormControl sx={{ m: 1, minWidth: 250 }}>
                    <InputLabel id="demo-simple-select-helper-label">Exploration Objectives</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={explorationObjectiveSelection}
                        label="Exploration Objectives"
                        onChange={handleExplorationObjectiveSelection}
                    >
                        {explorationObjectives.map((explorationObjective) =>
                            (<MenuItem value={explorationObjective.id}>{explorationObjective.name}</MenuItem>)
                        )}
                    </Select>
                </FormControl>
            </Stack>


            <NavBarBottom
                cancelPage={'/colorexplorationhome'}
                prevPage={'/colorexplorationsetupname'}
                nextPage={'/colorexplorationsetuplaserselect'}
                enableNextPage={(explorationObjectiveSelection != null) ? false : true}
                nextPageClickFunc={nextPageClickFunc}>
            </NavBarBottom>

        </Stack>
    );
}