import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { TextField, Stack, Button,  IconButton, OutlinedInput, InputLabel, InputAdornment, FormControl } from '@mui/material';
import { LockReset, Visibility, VisibilityOff } from "@mui/icons-material";

function PasswordChange() {
  const navigate = useNavigate();

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [showNewPassword1, setShowNewPassword1] = useState(false);
  const [showNewPassword2, setShowNewPassword2] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);

  const handlePassword1Change = (event) => {
    setPassword1(event.target.value);
  };
  const handlePassword2Change = (event) => {
    setPassword2(event.target.value);
  };
  const handleOldPasswordChange = (event) => {
    setOldPassword(event.target.value);
  };
  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword)
  };
  const handleMouseDownOldPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowNewPassword1 = () => {
    setShowNewPassword1(!showNewPassword1)
  };
  const handleClickShowNewPassword2 = () => {
    setShowNewPassword2(!showNewPassword2)
  };
  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };
  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      new_password1: password1,
      new_password2: password2,
      old_password: oldPassword,
    };
    await axios
      .post("oraclase/auth/password/change/", data)
      .then((res) => {
        if (res.status === 200) {
          navigate("/profile");
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (

    <Stack
      direction='column'
      justifyContent='center'
      alignItems='center'
      spacing={4}
      marginTop='2%'
    >
      <TextField
        disabled
        id="password-change-page"
        label='Your change password'
        sx={{ width: '40ch' }}
        variant="filled"
      />
      <FormControl sx={{ width: '40ch' }} variant="outlined">
        <InputLabel>Old password</InputLabel>
        <OutlinedInput
          required={true} //TODO: required does not work
          label="Old password"
          id="oldPassword"
          type={showOldPassword ? 'text' : 'password'}
          value={oldPassword}
          onChange={handleOldPasswordChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowOldPassword}
                onMouseDown={handleMouseDownOldPassword}
                edge="end"
              >
                {showOldPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>

      <FormControl sx={{ width: '40ch' }} variant="outlined">
        <InputLabel>Enter new password</InputLabel>
        <OutlinedInput
          required={true} //TODO: required does not work
          label="New password"
          id="password1"
          type={showNewPassword1 ? 'text' : 'password'}
          value={password1}
          onChange={handlePassword1Change}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowNewPassword1}
                onMouseDown={handleMouseDownPassword1}
                edge="end"
              >
                {showNewPassword1 ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>

      <FormControl sx={{ width: '40ch' }} variant="outlined">
        <InputLabel>Repeat new password</InputLabel>
        <OutlinedInput
          required={true} //TODO: required does not work
          label="Repeat new password"
          id="password2"
          type={showNewPassword2 ? 'text' : 'password'}
          value={password2}
          onChange={handlePassword2Change}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowNewPassword2}
                onMouseDown={handleMouseDownPassword2}
                edge="end"
              >
                {showNewPassword2 ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>

      <Button
        variant="contained"
        startIcon={<LockReset />}
        style={{ width: '41ch', height: "60px", marginLeft: "10px", marginTop: '2%' }}
        onClick={handleSubmit}
      >
        Change password
      </Button>
    </Stack>

  )
}

export default PasswordChange;
