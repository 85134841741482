import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Stack, Box, Button } from "@mui/material";
import NavBarBottom from "../../components/NavBarBottom";
import ProgressBarTop from "../../components/ProgressBarTop";
import { PROGRESS_BAR_TOTAL_STEPS } from "../../helper/colorExplorationParameters";
import ImageGrid from "../../components/ImageGrid";
import ImagePreview from "../../components/ImagePreview";
import ImagePreview_backup from "../../components/ImagePreview_backup";

import axios from "axios";

export default function ColorExplorationMeasureWidth() {
  const [imageObjects, setImageObjects] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [cluster_width, setCluster_width] = useState(null);
  const [row, setRow] = useState(null);
  const [column, setColumn] = useState(null);
  const [width_pixel, setWidth_pixel] = useState(null);
  const [width_metric, setWidth_metric] = useState(null);
  const [patch_type, setPatch_type] = useState(null);
  const [method, setMethod] = useState(null);
  const [index, setIndex] = useState(null);
  const [gridSize, setGridSize] = useState(7);
  // const [showGrid, setShowGrid] = useState(false);
  const [imageObjectsDownloaded, setImageObjectsDownloaded] = useState(JSON.parse(localStorage.getItem("imageObjectsDownloaded")));
  const navigate = useNavigate();


  const prepare_image_data = async (downloaded_data) => {
    console.log(downloaded_data.length);
    console.log(downloaded_data);

    try {
      const imageArray = await Promise.all(downloaded_data.map(async (image_data) => {
        const image = await download_patch_image(image_data.cluster_patch_image);
        const coordinates = [image_data.edge_a_location, image_data.edge_b_location];
        const cluster_width = image_data.cluster_width;
        const row = image_data.row;
        const column = image_data.column;
        const width_pixel = image_data.width_pixel;
        const width_metric = image_data.width_metric;
        const method = image_data.method;
        const patch_type = image_data.patch_type;

        return {
          image,
          coordinates,
          cluster_width,
          row,
          column,
          width_pixel,
          width_metric,
          method,
          patch_type
        };
      }));

      setImageObjectsDownloaded(imageArray);
      // setShowGrid(true);
      // console.log("The moment before storing the images downloaded")
      // console.log(JSON.stringify(imageArray));
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  useEffect(() => {
    console.log("calling use effect measure width as the image objected downloaded is changed");
    // onClickMeasureWidthButton();
    console.log(JSON.parse(localStorage.getItem("imageObjectsDownloaded")))
    prepare_image_data(JSON.parse(localStorage.getItem("imageObjectsDownloaded")));
  }, []);

  const onClickMeasureWidthButton = async () => {
    // console.log("Camer inside the onclick measure width");
    // const fetchData = async () => {
    //   const formData = new FormData();
    //   const request_data = {
    //     "imageToQuantify": "users/" + localStorage.getItem("userId") + "/explorations/" + localStorage.getItem("explorationId") + "/Q" + localStorage.getItem("iterationNumber") + "/clusterImg.tif",
    //     "iterationNumber": localStorage.getItem("iterationNumber"),
    //     "explorationId": localStorage.getItem("explorationId")
    //   }
    //   await axios.post('api/exploration/measure/width', request_data)
    //     .then(response => {
    //       setImageObjects(response.data.results);
    //       prepare_image_data(response.data.results);
    //     })
    //     .catch(error => {
    //       console.error('Error:', error);
    //     });
    // };
    // await fetchData();
  }




  const download_patch_image = async (cloud_file_path) => {
    console.log("downloading this image");
    console.log(cloud_file_path);

    try {
      const data = {
        fileDestination: cloud_file_path
      };
      const response = await axios.post('api/patch/file/get', data, {
        responseType: 'blob',
      });
      const fileUrl = URL.createObjectURL(response.data);
      return fileUrl;
    } catch (error) {
      console.log('Error:', error);
      throw error; // Re-throw the error to be caught by the caller
    }
  };

  // const updateGridCellContent = (coordinates, cluster_width, row, column, width_pixel, width_metric, method, patch_type) => {
  //   console.log("Updating the grid cell");
  //   //const imageArray = [];
  //   const image_data = imageObjectsDownloaded[index];
  //   const image = image_data.image;
  //   imageObjectsDownloaded[index] = {image, coordinates, cluster_width, row, column, width_pixel, width_metric, method, patch_type};

  //   // for (var i = 0; i < imageObjectsDownloaded.length; i++) {
  //   //   if (i === index) {
  //   //     const image_data = imageObjectsDownloaded[i];
  //   //     const image = image_data.image;
  //   //     imageArray.push({ image, coordinates, cluster_width, row, column, width_pixel, width_metric, method, patch_type });
  //   //   }
  //   //   else {
  //   //     imageArray.push(imageObjectsDownloaded[i]);
  //   //   }

  //   // }
  //   setImageObjectsDownloaded(imageObjectsDownloaded);
  // };



  const updateGridCellContent = (coordinates, cluster_width, row, column, width_pixel, width_metric, method, patch_type, index) => {
    console.log("Updating the grid cell in the parent page");
    console.log(coordinates);
    console.log(cluster_width);
    console.log(row);
    console.log(column);
    console.log(width_pixel);
    console.log(width_metric);
    console.log(method);
    console.log(patch_type);
    console.log(index);
    console.log("Printed all the passed values");
    const updatedImageObjects = [...imageObjectsDownloaded];
    const image_data = updatedImageObjects[index];
    console.log("The data that needs to be updated is");
    console.log(image_data);
    if (image_data) {
      console.log("Came inside the place whre the data is going to be updated");
      // Update the specific cell in the copied array
      updatedImageObjects[index] = {
        ...image_data,
        coordinates,
        cluster_width,
        row,
        column,
        width_pixel,
        width_metric,
        method,
        patch_type
      };
      console.log(updatedImageObjects);
      console.log(updatedImageObjects[index]);
      console.log("setting the image objects downloaded after updating the data");
      // Set the state with the modified array
      setImageObjectsDownloaded(updatedImageObjects);
    }
  };

  const handleImageClick = (image, coordinates, cluster_width, row, column, width_pixel, width_metric, method, patch_type, index) => {
    setSelectedImage(image);
    setCoordinates(coordinates);
    setCluster_width(cluster_width);
    setRow(row);
    setColumn(column);
    setWidth_pixel(width_pixel);
    setWidth_metric(width_metric);
    setMethod(method);
    setPatch_type(patch_type);
    setIndex(index);
  };

  const nextPageClickFunc = (navigatePageName) => {
    const request_data = {
      "explorationId": localStorage.getItem("explorationId"),
      "patchType": "FULL_CLUSTER"
    }
    axios.post('api/mark/collection', request_data)
      .then(response => {
      })
      .catch(error => {
        console.error('Error:', error);
      });
    navigate(navigatePageName);
  };

  return (
    <Stack direction="column" spacing={2}>
      <Box sx={{ width: '100%' }}>
        <ProgressBarTop currentStepNumber={5} totalStepNumber={PROGRESS_BAR_TOTAL_STEPS} progressBarName='Exploration' />
      </Box>

      <Box sx={{ width: '100%' }}>
        <ProgressBarTop currentStepNumber={1} totalStepNumber={3} progressBarName='Color - Resolution' />
      </Box>
      {/* {
        // !showGrid &&
        <Button variant="outlined" onClick={onClickMeasureWidthButton}>
          Measure Width
        </Button>
      } */}

      {
        imageObjectsDownloaded != null && imageObjectsDownloaded.length > 0 &&
        /* {showGrid && */
        <Stack direction="row" spacing={1} justifyContent="center"
          alignItems="center">
          <ImageGrid imageObjects={imageObjectsDownloaded} gridSize={gridSize} updateGridCellContent={updateGridCellContent} />
          {/* <ImagePreview_backup selectedImage={localStorage.getItem("previewImage")} coordinates={coordinates} cluster_width={cluster_width} row={row} column={column} width_pixel={width_pixel} width_metric={width_metric} method={method} patch_type={patch_type} updateGridCellContent={updateGridCellContent} /> */}
        </Stack>
      }

      <NavBarBottom
        cancelPage={'/colorexplorationhome'}
        prevPage={'/colorexplorationcolorresolution'}
        nextPage={'/colorexplorationcolorcolor'}
        enableNextPage={false}
        nextPageClickFunc={nextPageClickFunc}
      />
    </Stack>
  );
}
