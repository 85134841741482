import React from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Cell } from 'recharts';

const MyScatterChart = ({ dataColor }) => {
  // Calculate min and max values for 'a_value' and 'b_value'
  const minAValue = Math.min(...dataColor.map(entry => entry.a_value));
  const maxAValue = Math.max(...dataColor.map(entry => entry.a_value));
  const minBValue = Math.min(...dataColor.map(entry => entry.b_value));
  const maxBValue = Math.max(...dataColor.map(entry => entry.b_value));

  // Calculate domain for XAxis and YAxis
  const xDomain = [Math.floor(minAValue), Math.ceil(maxAValue)];
  const yDomain = [Math.floor(minBValue), Math.ceil(maxBValue)];

  return (
    <ScatterChart width={400} height={350} margin={{ top: 0, right: 0, left: 0, bottom: 5 }}>
      <XAxis
        type="number"
        domain={xDomain}
        dataKey="a_value"
        name="CIE-a*"
        label={{ value: "CIE-a*", angle: 0, position: 'insideBottom', offset: -5 }}
      />
      <YAxis
        type="number"
        domain={yDomain}
        dataKey="b_value"
        name="CIE-b*"
        label={{ value: "CIE-b*", angle: -90, position: 'center' }}
      />
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip />
      <Scatter data={dataColor}>
        {dataColor.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={`rgb(${entry.color.join(',')})`} />
        ))}
      </Scatter>
    </ScatterChart>
  );
};

export default MyScatterChart;
